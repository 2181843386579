import {
  ITelegramBot,
  ITelegramBotAccess,
} from '../../telegram-bots/telegram-bot.interface';
import axios from '../axios';

const updateTelegramBotAccesses = async (
  botId: string,
  accesses: ITelegramBotAccess[],
) => {
  const accessesData = accesses.map(
    ({
      messenger,
      broadcasts,
      channels,
      flows,
      user,
      statistics,
      variables,
    }) => ({
      user,
      broadcasts,
      channels,
      flows,
      statistics,
      messenger,
      variables,
    }),
  );
  const { data } = await axios.patch(`/telegram-bots/${botId}/accesses`, {
    accesses: accessesData,
  });

  return data as ITelegramBot;
};

export default updateTelegramBotAccesses;
