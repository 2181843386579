import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import DoDisturbAltIcon from '@mui/icons-material/DoDisturbAlt';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import SettingsIcon from '@mui/icons-material/Settings';
import {
  Button,
  Chip,
  Divider,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import React, { FC, useContext, useEffect, useState } from 'react';

import startFlowDialog from '../../api/dialogs/start-flow-dialog';
import { AppContext } from '../../AppContext';
import {
  IMedia,
  ITag,
  TelegramButton,
  Params,
  TelegramMessageType,
} from '../../interfaces/common';
import { BotContext } from '../../telegram-bots/bot.context';
import {
  FlowTypes,
  IFlowWithActivatorsAndFolder,
} from '../../telegram-bots/flows/interfaces';
import truncate from '../../utils/truncate';
import { MessengerContext, TelegramDialog } from '../messenger.context';
import { ITemplate } from '../templates/interfaces';

import AddTagModal from './add-tag.modal';
import CreateTagModal from './create-tag.modal';
import DialogDescription from './dialog-description';
import EditParamsModal from './edit-params.modal';
import EditTagModal from './edit-tag.modal';
import TemplatesBox from './templates.box';

interface CurrentDialogInfoProps {
  dialog: TelegramDialog;
  templates: ITemplate[];
  onTemplateClick: (
    text: string,
    buttons: TelegramButton[][],
    media: IMedia[],
    type: TelegramMessageType,
  ) => void;
  isBot: boolean;
}

const CurrentDialogInfo: FC<CurrentDialogInfoProps> = ({
  dialog,
  templates,
  onTemplateClick,
  isBot,
}) => {
  const { flows = [] } = useContext(BotContext);

  const {
    createTag,
    tags,
    assignTags,
    toggleBlockStatus,
    messengerType,
    deleteDialogParam,
  } = useContext(MessengerContext);

  const { openConfirmationDialog } = useContext(AppContext);

  const [dialogTags, setDialogTags] = useState<ITag[]>([]);
  const [paramsExpanded, setParamsExpanded] = useState<boolean>(false);
  const [channelMemberExpanded, setChannelMemberExpanded] =
    useState<boolean>(false);
  const [isCreateTagModalOpen, setIsCreateTagModal] = useState<boolean>(false);
  const [isEditTagModalOpen, setIsEditTagModal] = useState<boolean>(false);
  const [isAddTagModalOpen, setIsAddTagModal] = useState<boolean>(false);
  const [isEditParamsModalOpen, setIsEditParamsModal] = useState<Params | null>(
    null,
  );

  const onCreateTag = (createInputTag: string) => {
    if (!createInputTag) return;

    createTag(dialog._id, createInputTag);
  };

  const deleteCurrentTagFromDialog = (tagId: ITag['_id']) => {
    assignTags(dialog._id, [
      ...dialogTags.filter((tag) => tag._id !== tagId).map((tag) => tag._id),
    ]);
  };

  const handleBlockStatus = () => {
    if (!dialog) return;
    toggleBlockStatus(dialog._id, !dialog.blockedByUser);
  };

  const onOpenCreateTagModal = () => {
    setIsCreateTagModal(true);
  };

  const onCloseCreateTagModal = () => {
    setIsCreateTagModal(false);
  };

  const onOpenEditTagModal = () => {
    setIsEditTagModal(true);
  };

  const onCloseTagEditModal = () => {
    setIsEditTagModal(false);
  };

  const onOpenAddTagModal = () => {
    setIsAddTagModal(true);
  };

  const onCloseAddTagModal = () => {
    setIsAddTagModal(false);
  };

  const onOpenParamsModal = (param: Params) => {
    if (!param) return;

    setIsEditParamsModal(param);
  };

  const onCloseParamsModal = () => {
    setIsEditParamsModal(null);
  };

  const onSendFlow = (flow: IFlowWithActivatorsAndFolder) => {
    openConfirmationDialog(
      `${t('common.send')} ${flow?.name}?`,
      t('common.send'),
      () => {
        startFlowDialog(dialog._id, flow._id);
      },
    );
  };

  const onOpenExtraInfo = (name: string) => {
    switch (name) {
      case 'params':
        setParamsExpanded((prev) => !prev);
        setChannelMemberExpanded(false);
        break;
      case 'channels':
        setParamsExpanded(false);
        setChannelMemberExpanded((prev) => !prev);
        break;
      default:
        setParamsExpanded(false);
        setChannelMemberExpanded(false);
    }
  };

  useEffect(() => {
    setDialogTags(
      dialog.tags.filter((tag) =>
        tags.map((dialogTag: ITag) => dialogTag._id === tag._id),
      ),
    );
  }, [tags]);

  useEffect(() => {
    setDialogTags(
      dialog.tags.filter((tag) =>
        tags.map((dialogTag: ITag) => dialogTag._id === tag._id),
      ),
    );
  }, [dialog]);

  return (
    <Stack sx={{ height: '100%' }}>
      <Stack
        sx={{
          mb: '12px',
          position: 'sticky',
          top: '0',
          zIndex: '1',
          backgroundColor: 'grey.13',
          borderBottom: paramsExpanded ? '1px solid' : 'none',
          borderColor: 'grey.10',
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{
            cursor: 'pointer',
            height: '59px',
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            gap="5px"
            sx={{
              width: isBot ? '50%' : '100%',
              borderBottom: '1px solid',
              borderLeft: '1px solid',
              backgroundColor: 'grey.13',
              borderColor: 'grey.10',
            }}
            onClick={() => onOpenExtraInfo('params')}
          >
            <KeyboardArrowDownRoundedIcon
              sx={{
                transform: paramsExpanded ? 'rotate(180deg)' : 'none',
                transition: 'transform 0.2s ease',
              }}
            />
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: '14px',
              }}
            >
              {t('common.parameters')}
            </Typography>
          </Stack>
          {isBot && (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              gap="5px"
              sx={{
                width: '50%',
                borderBottom: '1px solid',
                borderLeft: '1px solid',
                backgroundColor: 'grey.13',
                borderColor: 'grey.10',
              }}
              onClick={() => onOpenExtraInfo('channels')}
            >
              <KeyboardArrowDownRoundedIcon
                sx={{
                  transform: channelMemberExpanded ? 'rotate(180deg)' : 'none',
                  transition: 'transform 0.2s ease',
                }}
              />
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: '14px',
                }}
              >
                {t('common.channels')}
              </Typography>
            </Stack>
          )}
        </Stack>
        <Stack
          sx={{
            maxHeight: !paramsExpanded && !channelMemberExpanded ? 0 : 'unset',
            overflow: 'hidden',
          }}
        >
          <Stack
            gap="5px"
            sx={{
              padding: '12px 8px',
            }}
          >
            {paramsExpanded && (
              <Stack gap="5px">
                {Object.entries(dialog.params).map(([key, value], index) => (
                  <>
                    {index !== 0 && <Divider sx={{ m: '8px 0' }} />}
                    <Stack
                      key={'param' + index}
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      gap="20px"
                    >
                      <Typography
                        sx={{
                          fontSize: '14px',
                          fontWeight: 600,
                        }}
                      >
                        {key}:
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: '14px',
                          fontWeight: 600,
                        }}
                      >
                        {value ? value : '-'}
                      </Typography>
                      <Stack direction="row" gap="4px">
                        <Button
                          className="red"
                          sx={{
                            width: '40px',
                            minWidth: '40px',
                            height: '40px',
                          }}
                          onClick={() => {
                            openConfirmationDialog(
                              `${t('flowNodes.deleteParamDialog')} ${key}?`,
                              t('common.delete'),
                              () => {
                                deleteDialogParam(
                                  dialog._id,
                                  dialog.telegramId,
                                  dialog.project,
                                  key,
                                );
                              },
                            );
                          }}
                        >
                          <DeleteIcon />
                        </Button>
                        <Button
                          className="brightGreen"
                          sx={{
                            width: '40px',
                            minWidth: '40px',
                            height: '40px',
                          }}
                          onClick={() => onOpenParamsModal({ key, value })}
                        >
                          <EditIcon />
                        </Button>
                      </Stack>
                    </Stack>
                  </>
                ))}
                <EditParamsModal
                  param={isEditParamsModalOpen}
                  telegramId={dialog.telegramId}
                  project={dialog.project}
                  dialogId={dialog._id}
                  isEditParamsModalOpen={Boolean(!!isEditParamsModalOpen)}
                  onClose={onCloseParamsModal}
                />
              </Stack>
            )}

            {channelMemberExpanded &&
              dialog.channelMemberships?.map((member, index) => (
                <Stack
                  key={'member' + index}
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  gap="20px"
                >
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: 600,
                    }}
                  >
                    {truncate(member.telegramChannel.title ?? '', 22)}:
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: 600,
                    }}
                  >
                    {member.status}
                  </Typography>
                </Stack>
              ))}
          </Stack>
        </Stack>
      </Stack>
      <Stack sx={{ p: '0 10px' }}>
        <Stack flexDirection="row" gap="6px" alignItems="center">
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: 500,
            }}
          >
            {t('messenger.tagLabel')}
          </Typography>
          <IconButton onClick={onOpenEditTagModal}>
            <SettingsIcon
              sx={{
                width: '22px',
                height: '22px',
                color: 'blue.2',
              }}
            />
          </IconButton>
          <EditTagModal
            isEditTagModalOpen={isEditTagModalOpen}
            onClose={onCloseTagEditModal}
          />
          <CreateTagModal
            isCreateTagModalOpen={isCreateTagModalOpen}
            onClose={onCloseCreateTagModal}
            onCreateTag={onCreateTag}
          />
          <AddTagModal
            isAddTagModalOpen={isAddTagModalOpen}
            dialogTags={dialogTags}
            onClose={onCloseAddTagModal}
            dialogId={dialog._id}
          />
        </Stack>
        <Stack
          direction="row"
          gap="8px"
          sx={{
            flexWrap: 'wrap',
            m: '8px 0 12px',
          }}
        >
          {dialogTags.map((dialogTag) => {
            return (
              <Chip
                key={dialogTag._id}
                sx={{
                  backgroundColor: 'green.2',
                  color: 'grey.15',
                  borderRadius: '16px',
                  border: 'none',
                  padding: ' 6px 8px 6px 12px',
                  fontWeight: 600,
                  fontSize: '14px',
                  '& .MuiChip-deleteIcon': {
                    color: 'green.2',
                    fontSize: '18px',
                  },
                }}
                deleteIcon={
                  <CloseIcon
                    sx={{
                      backgroundColor: 'green.4',
                      borderRadius: '50%',
                    }}
                  />
                }
                variant="outlined"
                label={dialogTag.text}
                onDelete={() => deleteCurrentTagFromDialog(dialogTag._id)}
              />
            );
          })}
        </Stack>
        <Stack direction="row" gap="10px">
          <Button
            className="blue"
            sx={{
              height: '32px',
              width: '132px',
              fontWeight: 600,
              fontSize: '14px',
              display: 'flex',
              justifyContent: 'space-around',
            }}
            onClick={onOpenAddTagModal}
          >
            <AddIcon
              sx={{
                fontSize: '14px',
                color: 'grey.1',
              }}
            />
            {t('messenger.addTag')}
          </Button>
          <Button
            sx={{
              height: '32px',
              width: '132px',
              fontWeight: 600,
              fontSize: '14px',
              display: 'flex',
              justifyContent: 'space-around',
            }}
            className="blue"
            onClick={onOpenCreateTagModal}
          >
            <EditIcon
              sx={{
                fontSize: '14px',
                color: 'grey.1',
              }}
            />
            {t('messenger.createTag')}
          </Button>
        </Stack>
      </Stack>
      <Divider sx={{ margin: '12px 0' }} />
      <Stack
        sx={{
          p: '0 10px',
        }}
      >
        <Typography
          sx={{
            fontSize: '16px',
            fontWeight: 500,
          }}
        >
          {t('messenger.currentDialog.note.title')}
        </Typography>
        <DialogDescription dialog={dialog} />
      </Stack>
      <Divider sx={{ margin: '12px 0' }} />
      <Stack sx={{ px: '9px', height: 'auto', overflow: 'auto' }}>
        <TemplatesBox
          templates={templates}
          onTemplateClick={onTemplateClick}
          flows={
            flows?.filter((flow) => flow.type.includes(FlowTypes.messenger)) ??
            []
          }
          onSendFlow={onSendFlow}
          disableFlows={messengerType !== 'bot'}
        />
      </Stack>
      <Stack
        sx={{
          marginTop: 'auto',
          backgroundColor: 'grey.14',
          width: '100%',
        }}
      >
        <Button
          onClick={handleBlockStatus}
          sx={{
            width: '100%',
            color: dialog.blockedByUser ? 'green.2' : 'red.2',
            backgroundColor: 'transparent',
            height: '60px',
            cursor: 'pointer',
            '&:hover': { backgroundColor: 'transparent' },
          }}
          startIcon={
            dialog.blockedByUser ? (
              <LockOpenIcon
                sx={{
                  color: 'green.2',
                }}
              />
            ) : (
              <DoDisturbAltIcon
                sx={{
                  color: 'red.2',
                }}
              />
            )
          }
        >
          {dialog.blockedByUser
            ? t('messenger.unblockUser')
            : t('messenger.blockUser')}
        </Button>
      </Stack>
    </Stack>
  );
};

export default CurrentDialogInfo;
