import { ITelegramBot } from '../../telegram-bots/telegram-bot.interface';
import { UpdateTelegramUserAccess } from '../../telegram-user-accounts/telegram-user.interface';
import axios from '../axios';

const updateTelegramUserAccesses = async (
  userAccountId: string,
  accesses: UpdateTelegramUserAccess[],
) => {
  const { data } = await axios.patch(
    `/business-accounts/${userAccountId}/accesses`,
    {
      accesses,
    },
  );

  return data as ITelegramBot;
};

export default updateTelegramUserAccesses;
