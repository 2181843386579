import AttachFileIcon from '@mui/icons-material/AttachFile';
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import ReplyIcon from '@mui/icons-material/Reply';
import SendIcon from '@mui/icons-material/Send';
import SentimentSatisfiedRoundedIcon from '@mui/icons-material/SentimentSatisfiedRounded';
import { IconButton, Stack, TextField, Typography } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import EmojiPicker, {
  EmojiClickData,
  SkinTones,
  Theme,
} from 'emoji-picker-react';
import { t } from 'i18next';
import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react';

import { TELEGRAM_MESSAGE_MAX_LENGTH } from '../../common/constants/constants';
import { ITelegramBotDialogExtended } from '../../interfaces/common';
import truncate from '../../utils/truncate';

interface NewMessageInputProps {
  disabled?: boolean;
  messagePreviewModalOpen?: boolean;
  sendButtonDisabled?: boolean;
  openPreviewModal: () => void;
  onSubmit: (e: React.SyntheticEvent) => void;
  mediaAdded: boolean;
  newMessageText: string;
  onChangeNewMessageText: (e: ChangeEvent<HTMLInputElement>) => void;
  onEmojiPick: (data: EmojiClickData) => void;
  dialogId: ITelegramBotDialogExtended['_id'];
  editingMessage: boolean;
  isReplyMessage: boolean;
  replyMessageText: string | null;
  replyMessageMedia: string | null;
  onClearReplayEdit: () => void;
}

const NewMessageInput: FC<NewMessageInputProps> = ({
  disabled = false,
  dialogId,
  messagePreviewModalOpen,
  sendButtonDisabled = false,
  openPreviewModal,
  onSubmit,
  onChangeNewMessageText,
  newMessageText,
  mediaAdded,
  onEmojiPick,
  editingMessage,
  isReplyMessage,
  replyMessageText,
  replyMessageMedia,
  onClearReplayEdit,
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const [emojiPickerShown, setEmojiPickerShown] = useState(false);

  const toggleEmojiPicker = () => setEmojiPickerShown((prev) => !prev);

  useEffect(() => {
    if (inputRef.current && !messagePreviewModalOpen) {
      inputRef.current.focus();
    }
  }, [dialogId]);

  useEffect(() => {
    if (inputRef.current && editingMessage && !messagePreviewModalOpen) {
      inputRef.current.focus();
    }

    if (inputRef.current && isReplyMessage && !messagePreviewModalOpen) {
      inputRef.current.focus();
    }
  }, [editingMessage, newMessageText, isReplyMessage, messagePreviewModalOpen]);

  return (
    <form
      style={{
        width: '100%',
        marginTop: 'auto',
      }}
    >
      <Stack
        sx={{
          width: '100%',
          backgroundColor: 'grey.13',
          position: 'relative',
        }}
      >
        {editingMessage && (
          <Stack
            direction="row"
            gap="12px"
            sx={{
              p: '6px 21px',
              borderTop: '1px solid',
              borderColor: 'blue.2',
              backgroundColor: 'blue.1',
            }}
          >
            <EditIcon />
            <Typography>{t('common.editing')}</Typography>
            <IconButton sx={{ ml: 'auto', p: '0' }} onClick={onClearReplayEdit}>
              <ClearIcon />
            </IconButton>
          </Stack>
        )}
        {replyMessageText && !replyMessageMedia && (
          <Stack
            direction="row"
            alignItems="center"
            gap="8px"
            sx={{
              p: '6px 21px',
              borderTop: '1px solid',
              borderColor: 'blue.2',
              backgroundColor: 'blue.5',
            }}
          >
            <ReplyIcon />
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: '14px',
              }}
            >
              {truncate(replyMessageText, 70)}
            </Typography>
            <IconButton sx={{ ml: 'auto', p: '0' }} onClick={onClearReplayEdit}>
              <ClearIcon />
            </IconButton>
          </Stack>
        )}
        {replyMessageMedia && (
          <Stack
            direction="row"
            gap="8px"
            alignItems="center"
            sx={{
              p: '6px 21px',
              borderTop: '1px solid',
              borderColor: 'blue.2',
              backgroundColor: 'blue.5',
            }}
          >
            <ReplyIcon />
            <img
              alt=""
              src={replyMessageMedia}
              style={{
                width: '30px',
                height: '30px',
                borderRadius: '2px',
                backgroundSize: 'contain',
              }}
            />
            {replyMessageText && (
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: '14px',
                }}
              >
                {truncate(replyMessageText, 60)}
              </Typography>
            )}
            <IconButton sx={{ ml: 'auto', p: '0' }} onClick={onClearReplayEdit}>
              <ClearIcon />
            </IconButton>
          </Stack>
        )}
        <TextField
          disabled={disabled}
          sx={{ opacity: disabled ? '0.1' : '1' }}
          type="input"
          placeholder={t('messenger.messageInput.placeholder')}
          value={newMessageText}
          onChange={onChangeNewMessageText}
          multiline
          maxRows={6}
          inputRef={inputRef}
          onKeyDown={(e) => {
            const { shiftKey, key } = e;
            const shouldSend = !shiftKey && key === 'Enter';
            if (shouldSend) {
              onSubmit(e);
              setEmojiPickerShown(false);
            }
          }}
          inputProps={{
            maxLength: TELEGRAM_MESSAGE_MAX_LENGTH,
          }}
          InputProps={{
            sx: {
              '*.MuiInputBase-input': {
                fontWeight: 500,
                fontSize: '14px',
              },
              '*.MuiOutlinedInput-notchedOutline': {
                border: 'none',
                borderTop: '1px solid',
                borderColor: 'grey.10',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                border: 'none',
                borderTop: '1px solid',
                borderColor: 'grey.10',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                border: 'none',
                borderTop: '1px solid',
                borderColor: 'grey.10',
              },
            },
            startAdornment: (
              <InputAdornment
                disablePointerEvents={disabled}
                sx={{
                  cursor: 'pointer',
                  position: 'relative',
                  '&:hover': {
                    '& .attachments': {
                      display: 'flex',
                    },
                  },
                }}
                position="start"
              >
                <AttachFileIcon onClick={openPreviewModal} />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment
                sx={{
                  cursor:
                    newMessageText || mediaAdded ? 'pointer' : 'not-allowed',
                }}
                disablePointerEvents={disabled}
                position="start"
              >
                <>
                  <SentimentSatisfiedRoundedIcon
                    sx={{
                      mr: '10px',
                      cursor: 'pointer',
                      color: emojiPickerShown ? 'green.2' : 'grey.1',
                    }}
                    onClick={toggleEmojiPicker}
                  />
                  <SendIcon
                    sx={{
                      color: sendButtonDisabled
                        ? 'rgba(255,255,255, 0.3)'
                        : newMessageText || mediaAdded
                        ? 'green.2'
                        : 'grey.1',
                    }}
                    onClick={(e) => {
                      setEmojiPickerShown(false);
                      onSubmit(e);
                    }}
                  />
                </>
              </InputAdornment>
            ),
          }}
        />
        {newMessageText.length > 90 && (
          <Stack
            sx={{
              position: 'absolute',
              bottom: '10px',
              right: '15px',
              opacity: '0.4',
            }}
          >
            {TELEGRAM_MESSAGE_MAX_LENGTH}/{newMessageText.length}
          </Stack>
        )}
        {emojiPickerShown && (
          <Stack
            sx={{
              position: 'absolute',
              bottom: '110%',
              right: 10,
              zIndex: 1,
            }}
          >
            <EmojiPicker
              theme={Theme.DARK}
              searchDisabled
              onEmojiClick={onEmojiPick}
              defaultSkinTone={SkinTones.NEUTRAL}
              previewConfig={{
                showPreview: false,
              }}
            />
          </Stack>
        )}
      </Stack>
    </form>
  );
};

export default NewMessageInput;
