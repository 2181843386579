import { ITelegramBot } from '../../telegram-bots/telegram-bot.interface';
import axios from '../axios';

const editBotParams = async (_id: ITelegramBot['_id'], variables: string[]) => {
  const { data } = await axios.patch(`/telegram-bots/${_id}/params`, {
    params: variables,
  });

  return data;
};

export default editBotParams;
