import CallSplitIcon from '@mui/icons-material/CallSplit';
import CampaignRoundedIcon from '@mui/icons-material/CampaignRounded';
import ChatRoundedIcon from '@mui/icons-material/ChatRounded';
import MailIcon from '@mui/icons-material/Mail';
import RepeatIcon from '@mui/icons-material/Repeat';
import StarIcon from '@mui/icons-material/Star';
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';
import TrendingUpRoundedIcon from '@mui/icons-material/TrendingUpRounded';
import {
  Checkbox,
  Paper,
  Radio,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import React, { ChangeEvent, FC } from 'react';

import IconRobot from '../../../assets/icons/RobotIcon';
import Input from '../../../common/inputs/InputWithIcon';
import TooltipInfo from '../../../common/tooltip/tooltip';
import {
  ITelegramBotWithAccess,
  TelegramMessengerAccess,
} from '../../../telegram-bots/telegram-bot.interface';
import { ITelegramUserAccountWithAccess } from '../../../telegram-user-accounts/telegram-user.interface';
import truncate from '../../../utils/truncate';
import { ProjectInviteAccess } from '../../project.interface';

type TableInviteLinkProps = {
  accesses: ProjectInviteAccess[];
  checkAllCheckboxChange: (telegramBotId: string, value: boolean) => void;
  onChangeName: (event: ChangeEvent<HTMLInputElement>) => void;
  nameInviteLink: string;
  handleCheckboxChange: (
    telegramBotId: string,
    key: keyof ProjectInviteAccess,
    value: boolean | TelegramMessengerAccess,
  ) => void;
  groupOfBots: (ITelegramBotWithAccess | ITelegramUserAccountWithAccess)[];
};

const TableInviteLink: FC<TableInviteLinkProps> = ({
  accesses,
  onChangeName,
  nameInviteLink,
  checkAllCheckboxChange,
  handleCheckboxChange,
  groupOfBots,
}) => {
  return (
    <Stack
      sx={{
        m: '24px auto',
      }}
    >
      <Input
        value={nameInviteLink}
        handleChangeSearch={onChangeName}
        placeholder={t('common.nameLabel')}
        withIcon={false}
        backgroundNarrative="dark"
        fullWidth
      />
      <Stack
        gap="8px"
        sx={{
          mt: '12px',
        }}
      >
        {groupOfBots.map((bot) => {
          let botType = '';
          let currentBotId: string;

          if ('businessAccount' in bot) {
            botType = 'BUSINESS_ACCOUNT';
            currentBotId = bot.businessAccount._id ?? '';
          } else if ('bot' in bot) {
            botType = 'BOT';
            currentBotId = bot.bot._id ?? '';
          }
          const currentAccess = accesses.find(
            (acc) =>
              (acc.telegramBot === currentBotId && botType === 'BOT') ||
              (acc.businessAccount === currentBotId &&
                botType === 'BUSINESS_ACCOUNT'),
          );

          return (
            <Paper
              key={bot.access._id}
              sx={{ width: '100%', overflow: 'hidden', flex: '1 1 auto' }}
            >
              <TableContainer
                component={Paper}
                sx={{
                  width: '100%',
                  maxHeight: 440,
                  display: 'flex',
                  flexDirection: 'row',
                  backgroundColor: 'grey.14',
                  p: '0 16px',
                }}
              >
                <Stack
                  gap="6px"
                  alignItems="center"
                  sx={{
                    borderRight: '1px solid',
                    borderColor: 'grey.10',
                    p: '12px 0',
                    width: '100%',
                    minWidth: '120px',
                  }}
                >
                  {botType !== 'BOT' ? (
                    'bot' in bot &&
                    bot.bot.avatarUrl && (
                      <img
                        style={{
                          width: '50px',
                          height: '50px',
                          borderRadius: '50%',
                        }}
                        src={bot.bot.avatarUrl}
                        alt="#"
                      />
                    )
                  ) : (
                    <IconRobot
                      style={{
                        width: '50px',
                        height: '50px',
                        borderRadius: '50%',
                      }}
                    />
                  )}
                  {botType !== 'BUSINESS_ACCOUNT' ? (
                    'businessAccount' in bot &&
                    bot.businessAccount.avatarUrl && (
                      <img
                        style={{
                          width: '50px',
                          height: '50px',
                          borderRadius: '50%',
                        }}
                        src={bot.businessAccount.avatarUrl}
                        alt="#"
                      />
                    )
                  ) : (
                    <svg
                      width="50"
                      height="50"
                      viewBox="0 0 22 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="11"
                        cy="11"
                        r="10"
                        fill="#7E1DDE"
                        stroke="#1E1D1E"
                        strokeWidth="2"
                      />
                      <path
                        opacity="0.8"
                        d="M11 11C12.3812 11 13.5 9.88125 13.5 8.5C13.5 7.11875 12.3812 6 11 6C9.61875 6 8.5 7.11875 8.5 8.5C8.5 9.88125 9.61875 11 11 11ZM11 12.25C9.33125 12.25 6 13.0875 6 14.75V15C6 15.5523 6.44772 16 7 16H15C15.5523 16 16 15.5523 16 15V14.75C16 13.0875 12.6687 12.25 11 12.25Z"
                        fill="#FEFEFE"
                      />
                    </svg>
                  )}
                  <Stack direction="row" alignItems="center">
                    <Typography
                      sx={{
                        fontWeight: 600,
                        fontSize: '18px',
                      }}
                    >
                      {botType === 'BOT' && 'bot' in bot && bot.bot.name
                        ? truncate(bot.bot.name, 9)
                        : botType === 'BUSINESS_ACCOUNT' &&
                          'businessAccount' in bot &&
                          bot.businessAccount.name
                        ? truncate(bot.businessAccount.name, 15)
                        : ''}
                    </Typography>
                    {botType === 'BOT' && (
                      <Checkbox
                        checked={
                          !!(
                            currentAccess?.broadcasts &&
                            currentAccess?.channels &&
                            currentAccess?.flows &&
                            currentAccess?.variables &&
                            currentAccess?.statistics &&
                            (currentAccess?.messenger ===
                              TelegramMessengerAccess.manager ||
                              currentAccess?.messenger ===
                                TelegramMessengerAccess.admin)
                          )
                        }
                        style={{
                          color:
                            currentAccess?.broadcasts &&
                            currentAccess?.channels &&
                            currentAccess?.flows &&
                            currentAccess?.variables &&
                            currentAccess?.statistics &&
                            (currentAccess?.messenger ===
                              TelegramMessengerAccess.manager ||
                              currentAccess?.messenger ===
                                TelegramMessengerAccess.admin)
                              ? '#DEFF82'
                              : '#4b4a4a',
                        }}
                        onChange={(event) =>
                          checkAllCheckboxChange(
                            currentBotId,
                            event.target.checked,
                          )
                        }
                      />
                    )}
                  </Stack>
                </Stack>
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  sx={{
                    p: '12px 0',
                  }}
                >
                  <TableHead>
                    <TableRow
                      sx={{
                        position: 'sticky',
                        top: '0',
                        zIndex: '12',
                      }}
                    >
                      {botType === 'BOT' && (
                        <TableCell
                          sx={{
                            backgroundColor: 'transparent',
                            borderBottom: 'none',
                            padding: '16px 6px 1px',
                          }}
                          align="center"
                        >
                          <Stack flexDirection="column" alignItems="center">
                            <TooltipInfo
                              icon={
                                <CallSplitIcon
                                  sx={{ color: 'grey.5', fontSize: '18px' }}
                                />
                              }
                              title={t(
                                'projects.screen-new-telegram-bot.participantsTable.tooltips.accessFlow',
                              )}
                              placement="top"
                            />
                            <Typography
                              sx={{ fontSize: '10px', fontWeight: 600 }}
                            >
                              {t('common.flows')}
                            </Typography>
                          </Stack>
                        </TableCell>
                      )}
                      {botType === 'BOT' && (
                        <TableCell
                          sx={{
                            backgroundColor: 'transparent',
                            borderBottom: 'none',
                            padding: '16px 6px 1px',
                          }}
                          align="center"
                        >
                          <Stack flexDirection="column" alignItems="center">
                            <TooltipInfo
                              icon={
                                <MailIcon
                                  sx={{ color: 'grey.5', fontSize: '18px' }}
                                />
                              }
                              title={t(
                                'projects.screen-new-telegram-bot.participantsTable.tooltips.accessBroadcast',
                              )}
                              placement="top"
                            />
                            <Typography
                              sx={{ fontSize: '10px', fontWeight: 600 }}
                            >
                              {t('common.broadcasts')}
                            </Typography>
                          </Stack>
                        </TableCell>
                      )}
                      {botType === 'BOT' && (
                        <TableCell
                          sx={{
                            backgroundColor: 'transparent',
                            borderBottom: 'none',
                            padding: '16px 6px 1px',
                          }}
                          align="center"
                        >
                          <Stack flexDirection="column" alignItems="center">
                            <TooltipInfo
                              icon={
                                <CampaignRoundedIcon
                                  sx={{ color: 'grey.5', fontSize: '18px' }}
                                />
                              }
                              title={t(
                                'projects.screen-new-telegram-bot.participantsTable.tooltips.accessChannels',
                              )}
                              placement="top"
                            />
                            <Typography
                              sx={{ fontSize: '10px', fontWeight: 600 }}
                            >
                              {t('common.channels')}
                            </Typography>
                          </Stack>
                        </TableCell>
                      )}
                      {botType === 'BOT' && (
                        <TableCell
                          sx={{
                            backgroundColor: 'transparent',
                            borderBottom: 'none',
                            padding: '16px 6px 1px',
                          }}
                          align="center"
                        >
                          <Stack flexDirection="column" alignItems="center">
                            <TooltipInfo
                              icon={
                                <TrendingUpRoundedIcon
                                  sx={{ color: 'grey.5', fontSize: '18px' }}
                                />
                              }
                              title={t(
                                'projects.screen-new-telegram-bot.participantsTable.tooltips.accessStatistics',
                              )}
                              placement="top"
                            />
                            <Typography
                              sx={{ fontSize: '10px', fontWeight: 600 }}
                            >
                              {t('common.statistics')}
                            </Typography>
                          </Stack>
                        </TableCell>
                      )}
                      {botType === 'BOT' && (
                        <TableCell
                          sx={{
                            backgroundColor: 'transparent',
                            borderBottom: 'none',
                            padding: '16px 6px 1px',
                          }}
                          align="center"
                        >
                          <Stack flexDirection="column" alignItems="center">
                            <TooltipInfo
                              icon={
                                <RepeatIcon
                                  sx={{ color: 'grey.5', fontSize: '18px' }}
                                />
                              }
                              title={t(
                                'projects.screen-new-telegram-bot.participantsTable.tooltips.accessVariable',
                              )}
                              placement="top"
                            />
                            <Typography
                              sx={{ fontSize: '10px', fontWeight: 600 }}
                            >
                              {t('common.variables')}
                            </Typography>
                          </Stack>
                        </TableCell>
                      )}
                      <TableCell
                        sx={{
                          backgroundColor: 'transparent',
                          borderBottom: 'none',
                          padding: '16px 6px 1px',
                        }}
                        align="center"
                      >
                        <Stack flexDirection="column" alignItems="center">
                          <TooltipInfo
                            icon={
                              <ChatRoundedIcon
                                sx={{ color: 'grey.5', fontSize: '18px' }}
                              />
                            }
                            title={t(
                              'projects.screen-new-telegram-bot.participantsTable.tooltips.accessMessenger',
                            )}
                            placement="top"
                          />
                          <Typography
                            sx={{ fontSize: '10px', fontWeight: 600 }}
                          >
                            {t('common.messenger')}
                          </Typography>
                        </Stack>
                      </TableCell>
                      <TableCell
                        sx={{
                          backgroundColor: 'transparent',
                          borderBottom: 'none',
                          padding: '16px 6px 1px',
                        }}
                        align="center"
                      >
                        <Stack flexDirection="column" alignItems="center">
                          <TooltipInfo
                            icon={
                              <SupportAgentRoundedIcon
                                sx={{ color: 'grey.5', fontSize: '18px' }}
                              />
                            }
                            title={t(
                              'projects.screen-new-telegram-bot.participantsTable.tooltips.accessManager',
                            )}
                            placement="top"
                          />
                          <Typography
                            sx={{ fontSize: '10px', fontWeight: 600 }}
                          >
                            {t('common.manager')}
                          </Typography>
                        </Stack>
                      </TableCell>
                      <TableCell
                        sx={{
                          backgroundColor: 'transparent',
                          borderBottom: 'none',
                          padding: '16px 6px 1px',
                        }}
                        align="center"
                      >
                        <Stack flexDirection="column" alignItems="center">
                          <TooltipInfo
                            icon={
                              <StarIcon
                                sx={{ color: 'grey.5', fontSize: '18px' }}
                              />
                            }
                            title={t(
                              'projects.screen-new-telegram-bot.participantsTable.tooltips.accessAdmin',
                            )}
                            placement="top"
                          />
                          <Typography
                            sx={{ fontSize: '10px', fontWeight: 600 }}
                          >
                            {t('common.admin')}
                          </Typography>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow
                      sx={{
                        borderBottom: '1px solid rgba(81, 81, 81, 1)',
                      }}
                    >
                      {botType === 'BOT' && (
                        <TableCell
                          sx={{ border: 'none', padding: '9px 9px 6px' }}
                          align="center"
                        >
                          <Checkbox
                            checked={currentAccess?.flows || false}
                            style={{
                              color: currentAccess?.flows
                                ? '#DEFF82'
                                : '#4b4a4a',
                            }}
                            onChange={(event) =>
                              handleCheckboxChange(
                                currentBotId,
                                'flows',
                                event.target.checked,
                              )
                            }
                          />
                        </TableCell>
                      )}
                      {botType === 'BOT' && (
                        <TableCell
                          sx={{ border: 'none', padding: '9px 9px 6px' }}
                          align="center"
                        >
                          <Checkbox
                            checked={currentAccess?.broadcasts || false}
                            style={{
                              color: currentAccess?.broadcasts
                                ? '#DEFF82'
                                : '#4b4a4a',
                            }}
                            onChange={(event) =>
                              handleCheckboxChange(
                                currentBotId,
                                'broadcasts',
                                event.target.checked,
                              )
                            }
                          />
                        </TableCell>
                      )}
                      {botType === 'BOT' && (
                        <TableCell
                          sx={{ border: 'none', padding: '9px 9px 6px' }}
                          align="center"
                        >
                          <Checkbox
                            checked={currentAccess?.channels || false}
                            style={{
                              color: currentAccess?.channels
                                ? '#DEFF82'
                                : '#4b4a4a',
                            }}
                            onChange={(event) =>
                              handleCheckboxChange(
                                currentBotId,
                                'channels',
                                event.target.checked,
                              )
                            }
                          />
                        </TableCell>
                      )}
                      {botType === 'BOT' && (
                        <TableCell
                          sx={{ border: 'none', padding: '9px 9px 6px' }}
                          align="center"
                        >
                          <Checkbox
                            checked={currentAccess?.statistics || false}
                            style={{
                              color: currentAccess?.statistics
                                ? '#DEFF82'
                                : '#4b4a4a',
                            }}
                            onChange={(event) =>
                              handleCheckboxChange(
                                currentBotId,
                                'statistics',
                                event.target.checked,
                              )
                            }
                          />
                        </TableCell>
                      )}
                      {botType === 'BOT' && (
                        <TableCell
                          sx={{ border: 'none', padding: '9px 9px 6px' }}
                          align="center"
                        >
                          <Checkbox
                            checked={currentAccess?.variables || false}
                            style={{
                              color: currentAccess?.variables
                                ? '#DEFF82'
                                : '#4b4a4a',
                            }}
                            onChange={(event) =>
                              handleCheckboxChange(
                                currentBotId,
                                'variables',
                                event.target.checked,
                              )
                            }
                          />
                        </TableCell>
                      )}
                      <TableCell
                        align="center"
                        sx={{
                          border: 'none',
                          padding: '9px 9px 6px',
                        }}
                      >
                        <Checkbox
                          checked={
                            currentAccess?.messenger !==
                              TelegramMessengerAccess.none || false
                          }
                          style={{
                            color:
                              currentAccess?.messenger !==
                              TelegramMessengerAccess.none
                                ? '#DEFF82'
                                : '#4b4a4a',
                          }}
                          onChange={(event) =>
                            handleCheckboxChange(
                              currentBotId,
                              'messenger',
                              event.target.checked
                                ? TelegramMessengerAccess.manager
                                : TelegramMessengerAccess.none,
                            )
                          }
                        />
                      </TableCell>
                      <TableCell
                        sx={{ border: 'none', padding: '9px 9px 6px' }}
                        align="center"
                      >
                        <Radio
                          checked={
                            currentAccess?.messenger ===
                            TelegramMessengerAccess.manager
                          }
                          onChange={() =>
                            handleCheckboxChange(
                              currentBotId,
                              'messenger',
                              TelegramMessengerAccess.manager,
                            )
                          }
                          style={{
                            color:
                              currentAccess?.messenger ===
                              TelegramMessengerAccess.manager
                                ? '#DEFF82'
                                : '#4b4a4a',
                          }}
                          disabled={
                            currentAccess?.messenger ===
                            TelegramMessengerAccess.none
                          }
                        />
                      </TableCell>

                      <TableCell
                        sx={{ border: 'none', padding: '9px 9px 6px' }}
                        align="center"
                      >
                        <Radio
                          checked={
                            currentAccess?.messenger ===
                            TelegramMessengerAccess.admin
                          }
                          style={{
                            color:
                              currentAccess?.messenger ===
                              TelegramMessengerAccess.admin
                                ? '#DEFF82'
                                : '#4b4a4a',
                          }}
                          onChange={() =>
                            handleCheckboxChange(
                              currentBotId,
                              'messenger',
                              TelegramMessengerAccess.admin,
                            )
                          }
                          disabled={
                            currentAccess?.messenger ===
                            TelegramMessengerAccess.none
                          }
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          );
        })}
      </Stack>
    </Stack>
  );
};

export default TableInviteLink;
