import AddRoundedIcon from '@mui/icons-material/AddRounded';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { AppContext } from '../../AppContext';
import { APP_CONFIG } from '../../config';
import { TelegramLeadStatus } from '../../interfaces/common';
import { UserContext } from '../../user/user.context';
import { BotContext } from '../bot.context';
import { IFlowWithActivatorsAndFolder } from '../flows/interfaces';

interface CreateActivatorProps {
  postbackFlows: IFlowWithActivatorsAndFolder[];
}

interface IPostbackParam {
  key: string;
  value: string;
}

const BuildPostback: FC<CreateActivatorProps> = ({ postbackFlows }) => {
  const { user } = useContext(UserContext);
  const { triggerSnackbar } = useContext(AppContext);
  const { bot } = useContext(BotContext);

  const navigate = useNavigate();

  const [postbackFlow, setPostbackFlow] = useState<string | null>(null);
  const [leadStatuses, setLeadStatuses] = useState<string>('');
  const [params, setParams] = useState<IPostbackParam[]>([]);
  const [postbackLink, setPostbackLink] = useState('');

  const valid = useMemo(
    () =>
      params.every(({ key, value }) => Boolean(key) && Boolean(value)) &&
      Boolean(leadStatuses.length),
    [params, leadStatuses],
  );

  const toggleStatus = (status: string) => {
    setLeadStatuses(status);
  };

  const onAddParam = () => {
    setParams((prev) => [...prev, { key: '', value: '' }]);
  };

  const onParamChange = (
    index: number,
    fieldName: 'key' | 'value',
    newValue: string,
  ) => {
    setParams((prev) => {
      const result = [];

      for (let i = 0; i < prev.length; i++) {
        if (i !== index) {
          result.push(prev[i]);
          continue;
        }

        result.push({
          ...prev[i],
          [fieldName]: newValue,
        });
      }

      return result;
    });
  };

  const onParamDelete = (index: number) => {
    setParams((prev) => prev.filter((_, i) => i !== index));
  };

  const handleChangePostbackFlow = (e: SelectChangeEvent) => {
    const value = e.target.value;
    setPostbackFlow(value);
  };

  const handleChangeParamKey = (index: number, key: string) => {
    setParams((prev) => {
      return prev.map((item, i) => {
        if (i !== index) {
          return item;
        }
        return {
          ...item,
          key,
        };
      });
    });
  };

  const handleButtonClick = () => {
    if (!user) return;

    const queryParams = [];
    if (postbackFlow) {
      queryParams.push(`postbackflow=${postbackFlow}`);
    }
    if (leadStatuses) {
      queryParams.push(`status=${leadStatuses}`);
    }
    params.forEach((param) => {
      queryParams.push(`params[${param.key}]=${param.value}`);
    });
    const queryString = queryParams.join('&');
    setPostbackLink(
      `${APP_CONFIG.API_URL}/postback/{{clickId}}?${queryString}`,
    );
  };

  const copyText = () => {
    navigator.clipboard.writeText(postbackLink as string);
    triggerSnackbar(t('copyableText.text'));
  };

  useEffect(() => {
    if (valid) {
      handleButtonClick();
    }
  }, [params, leadStatuses, postbackFlow]);
  return (
    <Stack
      sx={{
        width: '100%',
      }}
    >
      {Boolean(postbackFlows.length) && (
        <FormControl fullWidth sx={{ mt: '21px' }}>
          <InputLabel
            sx={{
              fontWeight: 500,
              fontSize: '18px',
              color: 'grey.secondary',
            }}
          >
            {t('postback.flow')}
          </InputLabel>
          <Select
            sx={{
              height: '56px',
              border: 'none',
              backgroundColor: 'grey.15',
              fontWeight: 500,
              fontSize: '18px',
              color: 'grey.1',
              borderRadius: '6px',
              '& .MuiSelect-select': {
                paddingTop: '10px',
                paddingBottom: '10px',
              },
            }}
            label={t('postback.flow')}
            value={postbackFlow ?? ''}
            onChange={handleChangePostbackFlow}
          >
            {postbackFlows.map(({ _id, name }) => (
              <MenuItem key={_id} value={_id}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      <Stack sx={{ mt: '12px' }} direction="row" alignItems="center">
        <Typography sx={{ fontSize: '16px', fontWeight: 500, mr: '16px' }}>
          {t('common.status')}:
        </Typography>
        <Stack sx={{ width: '100%' }} gap="8px" direction="row">
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              borderRadius: '20px',
              p: '10px 24px',
              height: '40px',
              cursor: 'pointer',
              fontWeight: 600,
              fontSize: '14px',
              border: '2px solid',
              borderColor: 'green.2',
              color: leadStatuses.includes(TelegramLeadStatus.lead)
                ? 'grey.1'
                : 'grey.10',
              backgroundColor: leadStatuses.includes(TelegramLeadStatus.lead)
                ? 'green.5'
                : 'green.3',
            }}
            onClick={() => {
              toggleStatus(TelegramLeadStatus.lead);
            }}
          >
            Lead
          </Stack>

          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              borderRadius: '20px',
              p: '10px 24px',
              height: '40px',
              cursor: 'pointer',
              fontWeight: 600,
              fontSize: '14px',
              border: '2px solid',
              borderColor: 'green.2',
              color: leadStatuses.includes(TelegramLeadStatus.reg)
                ? 'grey.1'
                : 'grey.10',
              backgroundColor: leadStatuses.includes(TelegramLeadStatus.reg)
                ? 'green.5'
                : 'green.3',
            }}
            onClick={() => {
              toggleStatus(TelegramLeadStatus.reg);
            }}
          >
            Reg
          </Stack>
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              borderRadius: '20px',
              p: '10px 24px',
              height: '40px',
              cursor: 'pointer',
              fontWeight: 600,
              fontSize: '14px',
              border: '2px solid',
              borderColor: 'green.2',
              color: leadStatuses.includes(TelegramLeadStatus.dep)
                ? 'grey.1'
                : 'grey.10',
              backgroundColor: leadStatuses.includes(TelegramLeadStatus.dep)
                ? 'green.5'
                : 'green.3',
            }}
            onClick={() => {
              toggleStatus(TelegramLeadStatus.dep);
            }}
          >
            Dep
          </Stack>
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              borderRadius: '20px',
              p: '10px 24px',
              height: '40px',
              cursor: 'pointer',
              fontWeight: 600,
              fontSize: '14px',
              border: '2px solid',
              borderColor: 'green.2',
              color: leadStatuses.includes(TelegramLeadStatus.qual)
                ? 'grey.1'
                : 'grey.10',
              backgroundColor: leadStatuses.includes(TelegramLeadStatus.qual)
                ? 'green.5'
                : 'green.3',
            }}
            onClick={() => {
              toggleStatus(TelegramLeadStatus.qual);
            }}
          >
            Qual
          </Stack>
        </Stack>
      </Stack>
      <Stack
        sx={{
          mt: '24px',
        }}
      >
        {params.map((param, index) => (
          <Stack
            key={'param' + index}
            direction="row"
            alignItems="center"
            justify-content="end"
            sx={{
              mt: index && '10px',
            }}
          >
            <Box sx={{ width: '160px' }}>
              <FormControl fullWidth>
                <InputLabel sx={{ fontWeight: 500, fontSize: '18px' }}>
                  {t('common.key')}
                </InputLabel>
                <Select
                  value={param.key}
                  label={t('common.key')}
                  onChange={(e) => {
                    const value = e.target.value;
                    handleChangeParamKey(index, value);
                  }}
                  sx={{
                    mr: '8px',
                    fontWeight: 500,
                    fontSize: '18px',
                    backgroundColor: 'grey.15',
                    borderRadius: '6px',
                    height: '56px',
                    '.MuiSelect-select': {
                      py: '14px',
                    },
                  }}
                >
                  {Boolean(bot.params.length) ? (
                    bot.params.map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))
                  ) : (
                    <Stack sx={{ p: '10px', textAlign: 'center' }}>
                      <Typography
                        sx={{ mt: '10px', fontWeight: 500, fontSize: '14px' }}
                      >
                        {t('common.notFoundVariable')}
                      </Typography>
                      <Button
                        onClick={() =>
                          navigate(
                            `/project/${bot?.project}/bot/${bot?._id}/variables`,
                          )
                        }
                        sx={{ mt: '15px' }}
                      >
                        {t('common.createVariable')}
                      </Button>
                    </Stack>
                  )}
                </Select>
              </FormControl>
            </Box>
            <TextField
              sx={{
                mr: '12px',
                width: '70%',
                '& .MuiOutlinedInput-input': {
                  padding: '14px 5px',
                  pl: '16px',
                  fontWeight: 500,
                  fontSize: '18px',
                },
                '& .MuiInputBase-root': {
                  backgroundColor: 'grey.15',
                  height: '56px',
                  borderRadius: '6px',
                },
              }}
              placeholder={t('common.value')}
              value={param.value}
              onChange={(e) => {
                const value = e.target.value.trim();
                onParamChange(index, 'value', value);
              }}
            />
            <Button
              className="red"
              sx={{
                minWidth: '56px',
                width: '56px',
                height: '56px',
              }}
              onClick={() => {
                onParamDelete(index);
              }}
            >
              <DeleteIcon sx={{ color: 'grey.1' }} />
            </Button>
          </Stack>
        ))}
      </Stack>
      <Button
        fullWidth
        sx={{
          color: 'green.2',
          height: '36px',
          backgroundColor: 'grey.13',
          mt: '8px',
          fontWeight: 600,
          fontSize: '14px',
          '&:hover': {
            backgroundColor: 'grey.13',
          },
        }}
        onClick={onAddParam}
      >
        <AddRoundedIcon
          sx={{
            mr: '5px',
            fontWeight: 600,
            fontSize: '16px',
            color: 'green.2',
          }}
        />
        {t('channels.channelActivators.addParam')}
      </Button>
      {Boolean(postbackLink) && (
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="center"
          gap="15px"
          sx={{
            mt: '12px',
            border: '1px solid',
            borderColor: 'grey.13',
            borderRadius: '6px',
            p: ' 4px 0 4px 16px',
          }}
        >
          <Stack
            gap="12px"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            sx={{ width: '100%', wordBreak: 'break-all', cursor: 'pointer' }}
            onClick={copyText}
          >
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: '18px',
                color: 'green.3',
              }}
            >
              {postbackLink}
            </Typography>
            <Button
              className="blue"
              sx={{
                minWidth: '56px',
                width: '56px',
                height: '56px',
              }}
            >
              <ContentCopyIcon
                sx={{
                  color: 'grey.1',
                }}
              />
            </Button>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};
export default BuildPostback;
