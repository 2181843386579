import ImageIcon from '@mui/icons-material/Image';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Button, Divider, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { t } from 'i18next';
import React, { useContext } from 'react';

import refreshBotStatistic from '../../api/bots/refresh-bot-statistic';
import { ProjectContext } from '../../projects/project.context';
import { BotContext } from '../bot.context';

const BotCardStatistic = () => {
  const { bot } = useContext(BotContext);
  const { refetchBots } = useContext(ProjectContext);
  return (
    <Stack
      key={bot._id}
      sx={{
        border: '1px solid ',
        borderColor: 'grey.10',
        backgroundColor: 'grey.14',
        borderRadius: '18px',
        padding: '17px',
        width: ' 100%',
      }}
    >
      <Stack flexDirection="row" alignItems="center">
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{
            borderRadius: '50%',
            width: '56px',
            height: '56px',
            backgroundColor: 'grey.10',
            mr: '12px',
            overflow: 'hidden',
          }}
        >
          {bot.avatarUrl ? (
            <img
              style={{
                borderRadius: '50%',
                width: '56px',
                height: '56px',
              }}
              src={bot.avatarUrl}
              alt="avatar image"
            />
          ) : (
            <ImageIcon />
          )}
        </Stack>
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: '24px',
            lineHeight: '140%',
            color: 'grey.1',
          }}
        >
          {bot.name}
        </Typography>
        <Stack
          flexDirection="row"
          alignItems="center"
          gap="6px"
          sx={{
            marginLeft: 'auto',
          }}
        >
          <Stack
            sx={{
              marginRight: '20px',
            }}
          >
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: '16px',
                lineHeight: '140%',
                color: 'grey.1',
                opacity: '0.6',
              }}
            >
              {t('projects.lastUpdateStats')}
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: '16px',
                lineHeight: '140%',
                color: 'grey.1',
              }}
            >
              {dayjs(bot.statistics.statisticsUpdatedAt).fromNow(true)}
            </Typography>
          </Stack>
          <Button
            className="blue"
            sx={{
              width: '56px',
              height: '56px',
              minWidth: '56px',
              marginLeft: ' auto',
            }}
            onClick={() => refreshBotStatistic(bot._id).then(refetchBots)}
          >
            <RefreshIcon />
          </Button>
        </Stack>
      </Stack>
      <Divider
        sx={{
          m: '12px 0 16px ',
        }}
      />
      <Stack gap="8px" direction="row" justifyContent="space-between">
        <Stack>
          <Stack flexDirection="row" gap="6px">
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: '16px',
                lineHeight: '140%',
                color: 'grey.1',
                opacity: '0.6',
              }}
            >
              {t('projects.totalUsersCount')}
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: '16px',
                lineHeight: '140%',
                color: 'grey.1',
              }}
            >
              {bot.statistics.totalUsersCount}
            </Typography>
          </Stack>
          <Stack flexDirection="row" gap="6px">
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: '16px',
                lineHeight: '140%',
                color: 'grey.1',
                opacity: '0.6',
              }}
            >
              {t('projects.numberActiveUsers')}
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: '16px',
                lineHeight: '140%',
                color: 'grey.1',
              }}
            >
              {bot.statistics.activeUsersCount}
            </Typography>
          </Stack>
        </Stack>
        <Stack>
          <Stack flexDirection="row" gap="6px">
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: '16px',
                lineHeight: '140%',
                color: 'grey.1',
                opacity: '0.6',
              }}
            >
              {t('projects.bannedUsersCount')}
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: '16px',
                lineHeight: '140%',
                color: 'grey.1',
              }}
            >
              {bot.statistics.bannedUsersCount}
            </Typography>
          </Stack>
          <Stack flexDirection="row" gap="6px">
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: '16px',
                lineHeight: '140%',
                color: 'grey.1',
                opacity: '0.6',
              }}
            >
              {t('projects.regsCount')}
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: '16px',
                lineHeight: '140%',
                color: 'grey.1',
              }}
            >
              {bot.statistics.regsCount}
            </Typography>
          </Stack>
        </Stack>
        <Stack>
          <Stack flexDirection="row" gap="6px">
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: '16px',
                lineHeight: '140%',
                color: 'grey.1',
                opacity: '0.6',
              }}
            >
              {t('projects.depsCount')}
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: '16px',
                lineHeight: '140%',
                color: 'grey.1',
              }}
            >
              {bot.statistics.depsCount}
            </Typography>
          </Stack>
          <Stack flexDirection="row" gap="6px">
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: '16px',
                lineHeight: '140%',
                color: 'grey.1',
                opacity: '0.6',
              }}
            >
              {t('projects.qualsCount')}
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: '16px',
                lineHeight: '140%',
                color: 'grey.1',
              }}
            >
              {bot.statistics.qualsCount}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default BotCardStatistic;
