import { Button, Stack, Typography } from '@mui/material';
import { t } from 'i18next';
import { ChangeEvent, FC, useContext, useEffect, useState } from 'react';

import ModalBox from '../../../common/modal/modal-box';
import {
  ITelegramBotWithAccess,
  TelegramMessengerAccess,
} from '../../../telegram-bots/telegram-bot.interface';
import { ITelegramUserAccountWithAccess } from '../../../telegram-user-accounts/telegram-user.interface';
import { ProjectContext } from '../../project.context';
import { ProjectInviteAccess } from '../../project.interface';

import TableInviteLink from './table-invite-link';

type EditInviteLinkModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onEditProjectInviteLink: () => void;
  accesses: ProjectInviteAccess[];
  handleCheckboxChange: (
    telegramBotId: string,
    key: keyof ProjectInviteAccess,
    value: boolean | TelegramMessengerAccess,
  ) => void;
  checkAllCheckboxChange: (telegramBotId: string, value: boolean) => void;
  nameInviteLink: string;
  onChangeName: (e: ChangeEvent<HTMLInputElement>) => void;
};

const EditInviteLinkModal: FC<EditInviteLinkModalProps> = ({
  isOpen,
  onClose,
  onEditProjectInviteLink,
  accesses,
  handleCheckboxChange,
  checkAllCheckboxChange,
  nameInviteLink,
  onChangeName,
}) => {
  const { telegramBots, telegramUsers } = useContext(ProjectContext);

  const [groupOfBots, setGroupOfBots] = useState<
    (ITelegramBotWithAccess | ITelegramUserAccountWithAccess)[]
  >([]);

  const isValid =
    Boolean(nameInviteLink) &&
    accesses.some(
      (access) =>
        access.broadcasts ||
        access.channels ||
        access.flows ||
        access.messenger ||
        access.statistics,
    );

  const createBotsGroup = () => {
    const updatedGroupOfBots: (
      | ITelegramBotWithAccess
      | ITelegramUserAccountWithAccess
    )[] = [];

    for (const bot of telegramBots) {
      updatedGroupOfBots.push(bot);
      const users = telegramUsers.filter(
        (user) => user.businessAccount.telegramBot === bot.bot._id,
      );
      updatedGroupOfBots.push(...users);
    }

    return updatedGroupOfBots;
  };

  useEffect(() => {
    setGroupOfBots([...createBotsGroup()]);
  }, [telegramBots, telegramUsers]);
  return (
    <ModalBox
      open={isOpen}
      onClose={onClose}
      maxWidth="710px"
      sx={{
        p: '24px 32px 16px',
        borderRadius: '12px',
        backgroundColor: 'grey.14',
      }}
    >
      <Stack>
        <Typography
          sx={{ fontWeight: 600, fontSize: '24px', textAlign: 'center' }}
        >
          {t('projects.inviteLinks.editingInviteLink')}
        </Typography>
        <Stack>
          <TableInviteLink
            accesses={accesses}
            groupOfBots={groupOfBots}
            nameInviteLink={nameInviteLink}
            onChangeName={onChangeName}
            handleCheckboxChange={handleCheckboxChange}
            checkAllCheckboxChange={checkAllCheckboxChange}
          />
          <Stack direction="row" gap="8px">
            <Button fullWidth className="red" onClick={onClose}>
              {t('common.cancel')}
            </Button>
            <Button
              fullWidth
              className="brightGreen"
              onClick={onEditProjectInviteLink}
              disabled={!isValid}
            >
              {t('common.save')}
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </ModalBox>
  );
};

export default EditInviteLinkModal;
