import React, {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';

import { AppContext } from '../AppContext';
import { ProjectContext } from '../projects/project.context';

import {
  ITelegramUserAccess,
  ITelegramUserAccountSafe,
  TelegramUserMessengerMode,
} from './telegram-user.interface';

interface ITelegramUserContext {
  userAccount: ITelegramUserAccountSafe;
  access: ITelegramUserAccess;
  onUpdateMessengerMode: (
    success: boolean,
    newMode: TelegramUserMessengerMode,
  ) => void;
}

export const UserAccountContext = createContext<ITelegramUserContext>(
  {} as ITelegramUserContext,
);

const UserAccountContextProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const { telegramUsers } = useContext(ProjectContext);
  const { triggerSnackbar } = useContext(AppContext);

  const [userAccount, setUserAccount] = useState<ITelegramUserAccountSafe>();
  const [access, setAccess] = useState<ITelegramUserAccess>();

  const { botId } = useParams();

  useEffect(() => {
    const found = telegramUsers.find(
      ({ businessAccount }) => businessAccount._id === botId,
    );

    if (found) {
      setUserAccount(found.businessAccount);
      setAccess(found.access);
    }
  }, [botId, telegramUsers]);

  const onUpdateMessengerMode = (
    success: boolean,
    newMode: TelegramUserMessengerMode,
  ) => {
    if (!success) {
      triggerSnackbar('Error updating mode', 'error');
      return;
    }

    setAccess((prev) => {
      if (!prev) return prev;
      return { ...prev, messengerMode: newMode };
    });
  };

  if (!userAccount) return null;
  if (!access) return null;

  return (
    <UserAccountContext.Provider
      value={{
        userAccount,
        access,
        onUpdateMessengerMode,
      }}
    >
      {children}
    </UserAccountContext.Provider>
  );
};

export default UserAccountContextProvider;
