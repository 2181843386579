import AddIcon from '@mui/icons-material/Add';
import { alpha, Button, Stack } from '@mui/material';
import { t } from 'i18next';
import React, { useContext, useEffect, useState } from 'react';

import editBotParams from '../api/bots/edit-params.bot';
import { AppContext } from '../AppContext';
import { NAVBAR_HEIGHT_PX } from '../common/constants/constants';
import Input from '../common/inputs/InputWithIcon';
import ModalBox from '../common/modal/modal-box';
import { GlobalContext } from '../GlobalContext';
import { ProjectContext } from '../projects/project.context';
import useThemeColors from '../utils/hooks/useThemeColors';

import { BotContext } from './bot.context';
import { ITelegramBot } from './telegram-bot.interface';
import CreateEditVariableModal from './variables/create-edit-variable.modal';
import VariableTable from './variables/variable.table';

const VariablesTab = () => {
  const colors = useThemeColors();

  const { refetchProjects } = useContext(GlobalContext);
  const { triggerSnackbar } = useContext(AppContext);
  const { refetchBots } = useContext(ProjectContext);
  const { bot } = useContext(BotContext);

  const [params, setParams] = useState<ITelegramBot['params']>([]);

  const [search, setSearch] = useState('');
  const [currentVariable, setCurrentVariable] = useState<string | null>(null);
  const [currentVariableIndex, setCurrentVariableIndex] = useState<number>(-1);
  const [isVariablesModalOpen, setIsVariablesModalOpen] =
    useState<boolean>(false);
  const [isCreateVariable, setIsCreateVariable] = useState<boolean>(false);

  const onChangeVariableItem = (value: string) => {
    if (isCreateVariable) {
      setCurrentVariable(value);
    } else {
      setCurrentVariable(value);
      setParams((prevParams) => {
        const newParams = [...prevParams];
        newParams[currentVariableIndex] = value;
        return newParams;
      });
    }
  };

  const onCloseModalVariable = () => {
    setIsCreateVariable(false);
    setCurrentVariable(null);
    setIsVariablesModalOpen(false);
    setParams(bot.params);
  };

  const openEditVariableModal = (index: number, variable: string) => {
    setCurrentVariable(variable);
    setCurrentVariableIndex(index);
    setIsVariablesModalOpen(true);
    setIsCreateVariable(false);
  };

  const onOpenCreateModal = () => {
    setIsVariablesModalOpen(true);
    setIsCreateVariable(true);
  };

  const onSubmitVariable = () => {
    if (isCreateVariable && currentVariable) {
      if (params.includes(currentVariable)) {
        return triggerSnackbar(t('triggerSnackbar.paramExists'), 'error');
      }
      editBotParams(bot._id, [...bot.params, currentVariable])
        .then(() => {
          refetchBots();
          triggerSnackbar(t('triggerSnackbar.success'), 'success');
        })
        .catch(() => triggerSnackbar(t('triggerSnackbar.error'), 'error'));
      setCurrentVariable(null);
      setCurrentVariableIndex(-1);
    } else {
      editBotParams(bot._id, [...params])
        .then(() => {
          refetchBots();
          triggerSnackbar(t('triggerSnackbar.success'), 'success');
        })
        .catch(() => triggerSnackbar(t('triggerSnackbar.error'), 'error'));
      setCurrentVariable(null);
      setCurrentVariableIndex(-1);
    }
  };

  const onDeleteVariable = (index: number) => {
    editBotParams(bot?._id, [...params.filter((_, i) => i !== index)])
      .then(() => {
        refetchProjects();
        triggerSnackbar(t('triggerSnackbar.success'), 'success');
      })
      .catch(() => triggerSnackbar(t('triggerSnackbar.error'), 'error'));
    setCurrentVariable(null);
    setCurrentVariableIndex(-1);
  };

  useEffect(() => {
    setParams(bot.params);
  }, [bot]);

  return (
    <Stack
      sx={{
        height: `calc(100vh - ${NAVBAR_HEIGHT_PX}px)`,
        width: '100%',
        padding: '0 24px',
        margin: '0 auto',
        overflowX: 'hidden',
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          position: 'sticky',
          top: '0',
          p: '16px 24px',
          mx: '-40px',
          px: '40px',
          backgroundColor: alpha(colors.grey['14'], 0.3),
          backdropFilter: 'blur(4px)',
          mb: '20px',
          borderBottom: '1.5px solid',
          borderColor: 'grey.10',
          zIndex: 3,
        }}
      >
        <Stack direction="row">
          <Button
            onClick={onOpenCreateModal}
            startIcon={<AddIcon />}
            className="brightGreen"
            sx={{
              width: '264px',
              height: '56px',
            }}
          >
            {t('variables.createVariableBtn')}
          </Button>
        </Stack>
        <Input
          value={search}
          handleChangeSearch={(e) => setSearch(e.target.value)}
        />
      </Stack>
      <Stack>
        <VariableTable
          variables={params}
          openEditVariableModal={openEditVariableModal}
          onDeleteVariable={onDeleteVariable}
        />
      </Stack>
      <ModalBox
        open={isVariablesModalOpen}
        onClose={onCloseModalVariable}
        sx={{
          border: '1px solid ',
          borderColor: 'grey.10',
          borderRadius: '12px',
          padding: '32px 56px',
          position: 'relative',
        }}
        maxWidth="458px"
      >
        <CreateEditVariableModal
          onClose={onCloseModalVariable}
          isCreateVariable={isCreateVariable}
          onSubmit={onSubmitVariable}
          variable={currentVariable}
          onChangeVariableItem={onChangeVariableItem}
        />
      </ModalBox>
    </Stack>
  );
};

export default VariablesTab;
