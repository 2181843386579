import { Button, Stack, TextField, Typography } from '@mui/material';
import { t } from 'i18next';
import React, { useState } from 'react';

import setTelegramAccountCode from '../../api/telegram-accounts/set-telegram-account-code';
import setTelegramAccountPassword from '../../api/telegram-accounts/set-telegram-account-password';

const UserAccountLogin = ({
  accountId,
  onPasswordSet,
}: {
  accountId: string;
  onPasswordSet: () => void;
}) => {
  const [code, setCode] = useState('');
  const [codeSet, setCodeSet] = useState(false);

  const [password, setPassword] = useState('');

  const [passwordSet, setPasswordSet] = useState(false);

  const onSendCode = () => {
    setTelegramAccountCode(accountId, code).then(() => {
      setCodeSet(true);
    });
  };

  const onSendPassword = () => {
    setTelegramAccountPassword(accountId, password).then(() => {
      setPasswordSet(true);
      onPasswordSet();
    });
  };

  return (
    <Stack
      flexDirection="column"
      alignItems="center"
      sx={{
        width: '400px',
        borderRadius: '8px',
        backgroundColor: 'grey.14',
        border: '1px solid',
        borderColor: 'grey.10',
        padding: '32px 56px',
        gap: '18px',
      }}
    >
      <Typography
        sx={{
          fontWeight: 600,
          fontSize: '24px',
          lineHeight: '140%',
          textAlign: 'center',
        }}
      >
        {t('projects.screen-telegram-user-account.login.title')}
      </Typography>
      <Stack>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '140%',
          }}
        >
          {t('projects.screen-telegram-user-account.login.codeTitle')}
        </Typography>
        <TextField
          sx={{
            mt: '8px',
          }}
          value={code}
          placeholder={t(
            'projects.screen-telegram-user-account.login.codePlaceholder',
          )}
          onChange={(e) => {
            setCode(e.target.value);
          }}
          disabled={codeSet}
        />
      </Stack>
      {codeSet && (
        <Stack
          sx={{
            mt: '16px',
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '140%',
            }}
          >
            {t('projects.screen-telegram-user-account.login.twoFactorTitle')}
          </Typography>
          <TextField
            sx={{
              mt: '8px',
            }}
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            placeholder={t(
              'projects.screen-telegram-user-account.login.twoFactorPlaceholder',
            )}
            disabled={passwordSet}
          />
        </Stack>
      )}
      <Button
        className="brightGreen"
        sx={{
          mt: '16px',
          width: '100%',
          height: '56px',
        }}
        disabled={Boolean(!code)}
        onClick={!codeSet ? onSendCode : onSendPassword}
      >
        {t('common.send')}
      </Button>
    </Stack>
  );
};

export default UserAccountLogin;
