import { useContext, useMemo } from 'react';

import { BotContext } from '../telegram-bots/bot.context';
import { UserAccountContext } from '../telegram-user-accounts/user-account.context';

export const getBotIdAndType = () => {
  const { bot } = useContext(BotContext);
  const { userAccount } = useContext(UserAccountContext);

  const botIdAndType = useMemo(() => {
    const botId = bot ? bot._id : userAccount._id;
    const botType = bot ? 'BOT' : 'BUSINESS_ACCOUNT';

    return { botId, botType };
  }, [bot, userAccount]);

  return { botId: botIdAndType.botId, botType: botIdAndType.botType };
};
