import { IUser } from '../user/user.interface';

export interface ITelegramUserAccount {
  canReply: boolean;
  enabled: boolean;
  _id: string;
  phone: string;
  session: string;
  name: string;
  telegramAppId: number;
  telegramAppHash: string;
  telegramBot: string;
  authorized: boolean;
  username: string;
  firstName: string;
  telegramId: number;
  avatarUrl: string;
  project: string;
  owner: string;
  createdAt: Date;
  updatedAt: Date;
}

export enum TelegramUserMessengerAccess {
  none = 'none',
  manager = 'manager',
  admin = 'admin',
}

export enum TelegramUserMessengerMode {
  online = 'online',
  silentOnline = 'silentOnline',
  offline = 'offline',
}

export interface ITelegramUserAccessFrontEnd {
  user: string;
  messenger: TelegramUserMessengerAccess;
}

export interface ITelegramUserAccess {
  _id: string;
  telegramUserAccount: string;
  user: string;
  messenger: TelegramUserMessengerAccess;
  messengerMode: TelegramUserMessengerMode;
  broadcasts: boolean;
  channels: boolean;
  statistics: boolean;
  variables: boolean;
  flows: boolean;
}

export interface ITelegramUserAccessWithUser {
  _id: string;
  telegramUserAccount: string;
  user: IUser;
  messenger: TelegramUserMessengerAccess;
  messengerMode: TelegramUserMessengerMode;
  createdAt: Date;
  updatedAt: Date;
}

export type CreateTelegramUserAccess = Omit<
  ITelegramUserAccess,
  '_id' | 'createdAt' | 'updatedAt' | 'messengerMode'
>;

export type UpdateTelegramUserAccess = Partial<
  Omit<
    ITelegramUserAccess,
    '_id' | 'createdAt' | 'updatedAt' | 'telegramUserAccount' | 'user'
  >
>;

export type ITelegramUserAccountSafe = Omit<ITelegramUserAccount, 'session'>;

export interface ITelegramUserAccountWithAccess {
  businessAccount: ITelegramUserAccountSafe;
  access: ITelegramUserAccess;
}

export type CreateTelegramUserAccount = Pick<
  ITelegramUserAccount,
  | 'project'
  | 'phone'
  | 'name'
  | 'telegramAppHash'
  | 'telegramAppId'
  | 'telegramBot'
>;

export type UpdateTelegramUserAccount = Partial<
  Omit<
    ITelegramUserAccount,
    '_id' | 'createdAt' | 'updatedAt' | 'owner' | 'project'
  >
>;
