import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import PersonIcon from '@mui/icons-material/Person';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  Button,
  CircularProgress,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import React, { FC, useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import deleteBot from '../../api/bots/delete-bot';
import editBot from '../../api/bots/edit-bot';
import refreshBot from '../../api/bots/refresh-bot';
import { AppContext } from '../../AppContext';
import CopyableText from '../../common/copyableText/copyableText';
import { ProjectContext } from '../../projects/project.context';
import { ITelegramBot } from '../telegram-bot.interface';

interface BotCardProps {
  bot: ITelegramBot;
}

const MAX_NAME_LENGTH = 25;

const BotCard: FC<BotCardProps> = ({ bot }) => {
  const { openConfirmationDialog, triggerSnackbar } = useContext(AppContext);
  const { project, refetchBots } = useContext(ProjectContext);

  const navigate = useNavigate();

  const [name, setName] = useState<string>(bot.name);
  const [token, setToken] = useState<string>(bot.token);
  const [loadingRefreshBot, setLoadingRefreshBot] = useState<boolean>(false);

  const onChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value.length <= MAX_NAME_LENGTH) {
      setName(value);
    }
  };

  const onChangeBotToken = (e: React.ChangeEvent<HTMLInputElement>) => {
    setToken(e.target.value.trim());
  };

  const onRefreshBot = async () => {
    setLoadingRefreshBot(true);
    await refreshBot(bot._id)
      .then(() => {
        refetchBots();
      })
      .catch(() =>
        triggerSnackbar(t('triggerSnackbar.errorRefreshBot'), 'error'),
      );
    setLoadingRefreshBot(false);
  };

  const onEdit = async () => {
    setLoadingRefreshBot(true);
    await editBot(bot._id, { name, token: token ? token : null })
      .then(() => {
        refetchBots();
        setToken('');
        triggerSnackbar(t('triggerSnackbar.successRefreshBot'), 'success');
      })
      .catch(() =>
        triggerSnackbar(t('triggerSnackbar.errorRefreshBot'), 'error'),
      );
    setLoadingRefreshBot(false);
  };

  const onDeleteClick = () => {
    openConfirmationDialog(
      `${t('bot-page.settings.deleteBotText')}`,
      `${t('common.delete')}`,
      () => {
        deleteBot(bot._id).then(() => {
          navigate(`/project/${project._id}`);
          refetchBots();
        });
      },
    );
  };

  const submitDisabled = useMemo(
    () => Boolean(name === bot.name) && Boolean(!token?.split(':')[0]),
    [name, token, bot],
  );
  return (
    <Stack
      sx={{
        maxWidth: '328px',
      }}
    >
      {loadingRefreshBot && (
        <Stack
          sx={{
            width: '328px',
            height: '500px',
            position: 'absolute',
            zIndex: '11',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress size={80} />
        </Stack>
      )}
      <Stack
        flexDirection="column"
        alignItems="center"
        sx={{
          width: '328px',
          borderRadius: '16px',
          backgroundColor: 'grey.14',
          border: '1px solid',
          borderColor: 'grey.10',
          padding: '0 16px 26px 16px',
          gap: '24px',
          opacity: loadingRefreshBot ? '0.5' : '1',
        }}
      >
        <Stack alignItems="center" sx={{ position: 'relative', mt: '-40px' }}>
          {bot.authorized ? (
            <Stack
              sx={{
                position: 'absolute',
                right: '2px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'green.2',
                border: '1.5px solid',
                borderColor: 'grey.1',
                borderRadius: '50%',
                zIndex: '2',
              }}
            >
              <CheckIcon
                sx={{
                  fontSize: '15px',
                  color: 'green.1',
                }}
              />
            </Stack>
          ) : (
            <Stack
              sx={{
                position: 'absolute',
                right: '2px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'red.2',
                borderRadius: '50%',
                zIndex: '2',
              }}
            >
              <CloseIcon
                sx={{
                  fontSize: '20px',
                  color: 'grey.1',
                }}
              />
            </Stack>
          )}
          {bot.avatarUrl ? (
            <img
              src={bot.avatarUrl}
              alt=""
              style={{
                width: '96px',
                height: '96px',
                borderRadius: '50%',
                position: 'relative',
              }}
            />
          ) : (
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{
                width: '96px',
                height: '96px',
                backgroundColor: 'grey.10',
                border: '2px solid',
                borderColor: bot.authorized ? 'green.2' : 'red.2',
                boxShadow: bot.authorized
                  ? '4px 4px 40px 0 rgba(203, 251, 69, 0.2)'
                  : ' 4px 4px 40px 0 rgba(255, 31, 110, 0.2)',
                borderRadius: '50%',
              }}
            >
              <PersonIcon
                sx={{
                  width: '53px',
                  height: '52px',
                }}
              />
            </Stack>
          )}
        </Stack>
        <Stack
          gap="12px"
          sx={{
            width: '100%',
          }}
        >
          <Stack sx={{ width: '100%' }}>
            <TextField
              fullWidth
              value={name}
              onChange={onChangeName}
              placeholder={t('common.name')}
            />
          </Stack>
          <Stack sx={{ width: '100%' }}>
            <Typography sx={{ mb: '4px', fontWeight: 500, fontSize: '15px' }}>
              Bot token
            </Typography>
            <TextField
              fullWidth
              value={token}
              onChange={onChangeBotToken}
              placeholder="Bot token"
            />
          </Stack>
          <Stack sx={{ width: '100%' }}>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: '15px',
              }}
            >
              Bot username
            </Typography>
            <CopyableText text={`@${bot.username}`} />
          </Stack>
          <Stack sx={{ width: '100%' }}>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: '15px',
              }}
            >
              Bot ID
            </Typography>
            <CopyableText text={`${bot._id}`} />
          </Stack>
        </Stack>
        <Stack
          direction="column"
          gap="12px"
          sx={{
            width: '100%',
          }}
        >
          <Button
            className="brightGreen"
            sx={{
              width: '100%',
              height: '52px',
            }}
            onClick={onEdit}
            disabled={submitDisabled}
          >
            {t('common.save')}
          </Button>
          <Button
            startIcon={<RefreshIcon />}
            className="blue"
            sx={{
              width: '100%',
              height: '52px',
            }}
            onClick={onRefreshBot}
          >
            {t('common.refresh')}
          </Button>
          <Stack
            sx={{
              width: '100%',
              color: 'blue.2',
              mt: '20px',
            }}
          >
            <Button
              className="red"
              sx={{
                width: '100%',
                height: '52px',
              }}
              onClick={onDeleteClick}
            >
              {t('common.delete')}
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
export default BotCard;
