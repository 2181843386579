import CheckIcon from '@mui/icons-material/Check';
import PersonIcon from '@mui/icons-material/Person';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  Button,
  CircularProgress,
  Divider,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import React, { FC, useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import deleteUserAccount from '../../api/telegram-accounts/delete-user-account';
import { AppContext } from '../../AppContext';
import CopyableText from '../../common/copyableText/copyableText';
import { ProjectContext } from '../../projects/project.context';
import { ITelegramUserAccountSafe } from '../telegram-user.interface';

import UserAccountLogin from './login';

interface TelegramUserCardProps {
  userAccount: ITelegramUserAccountSafe;
  loggingIn: boolean;
  toggleLoggingIn: (loggingIn: boolean) => void;
}

const MAX_NAME_LENGTH = 25;

const UserAccountCard: FC<TelegramUserCardProps> = ({
  userAccount,
  loggingIn,
  toggleLoggingIn,
}) => {
  const { openConfirmationDialog, triggerSnackbar } = useContext(AppContext);
  const { project, refetchUserAccounts } = useContext(ProjectContext);

  const navigate = useNavigate();

  const [name, setName] = useState<string>(userAccount.name);
  const [refreshLoading, setRefreshLoading] = useState<boolean>(false);

  const onChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value.length <= MAX_NAME_LENGTH) {
      setName(value);
    }
  };

  const onRefreshInfo = async () => {
    setRefreshLoading(true);
    try {
      triggerSnackbar('Not implemented yet', 'error');
    } catch (e) {
      triggerSnackbar(t('triggerSnackbar.errorRefreshBot'), 'error');
    }
    setRefreshLoading(false);
  };

  const onEdit = () => {
    try {
      triggerSnackbar('Not implemented yet', 'error');
    } catch (e) {
      triggerSnackbar(t('triggerSnackbar.errorRefreshBot'), 'error');
    }
  };

  const onPasswordSet = () => {
    setTimeout(() => {
      refetchUserAccounts();
      toggleLoggingIn(false);
    }, 1000);
  };

  const onDeleteClick = () => {
    openConfirmationDialog(
      `${t('bot-page.settings.deleteBotText')}`,
      `${t('common.delete')}`,
      () => {
        deleteUserAccount(userAccount._id).then(() => {
          navigate(`/project/${project._id}`);
          refetchUserAccounts();
        });
      },
    );
  };

  const submitDisabled = useMemo(
    () => Boolean(name === userAccount.name),
    [name, userAccount],
  );

  if (loggingIn) {
    return (
      <UserAccountLogin
        accountId={userAccount._id}
        onPasswordSet={onPasswordSet}
      />
    );
  }

  return (
    <Stack>
      {refreshLoading && (
        <Stack
          sx={{
            width: '280px',
            height: '500px',
            position: 'absolute',
            zIndex: '11',
            mt: '60px',
          }}
        >
          <CircularProgress size={80} />
        </Stack>
      )}

      <Stack
        flexDirection="column"
        alignItems="center"
        sx={{
          width: '328px',
          borderRadius: '16px',
          backgroundColor: 'grey.14',
          border: '1px solid',
          borderColor: 'grey.10',
          padding: '0 24px 32px 24px',
          gap: '18px',
          opacity: refreshLoading ? '0.5' : '1',
        }}
      >
        <Stack alignItems="center" sx={{ position: 'relative', mt: '-40px' }}>
          <Stack
            sx={{
              position: 'absolute',
              right: '2px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'green.2',
              border: '1.5px solid',
              borderColor: 'grey.1',
              borderRadius: '50%',
              zIndex: '2',
            }}
          >
            <CheckIcon
              sx={{
                fontSize: '15px',
                color: 'green.1',
              }}
            />
          </Stack>
          {/*<Stack*/}
          {/*  sx={{*/}
          {/*    position: 'absolute',*/}
          {/*    right: '2px',*/}
          {/*    display: 'flex',*/}
          {/*    justifyContent: 'center',*/}
          {/*    alignItems: 'center',*/}
          {/*    backgroundColor: 'red.2',*/}
          {/*    borderRadius: '50%',*/}
          {/*    zIndex: '2',*/}
          {/*  }}*/}
          {/*>*/}
          {/*  <CloseIcon*/}
          {/*    sx={{*/}
          {/*      fontSize: '20px',*/}
          {/*      color: 'grey.1',*/}
          {/*    }}*/}
          {/*  />*/}
          {/*</Stack>*/}
          {userAccount.avatarUrl ? (
            <img
              src={userAccount.avatarUrl}
              alt=""
              style={{
                marginTop: '-40px',
                width: '96px',
                height: '96px',
                borderRadius: '50%',
                position: 'relative',
              }}
            />
          ) : (
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{
                width: '96px',
                height: '96px',
                backgroundColor: 'grey.10',
                border: '2px solid',
                // borderColor: userAccount.authorized ? 'green.2' : 'red.2',
                borderColor: 'green.2',
                boxShadow: '4px 4px 40px 0 rgba(203, 251, 69, 0.2)',
                // boxShadow: userAccount.authorized
                // ? '4px 4px 40px 0 rgba(203, 251, 69, 0.2)'
                // : ' 4px 4px 40px 0 rgba(255, 31, 110, 0.2)',
                borderRadius: '50%',
              }}
            >
              <PersonIcon
                sx={{
                  width: '53px',
                  height: '52px',
                }}
              />
            </Stack>
          )}
        </Stack>
        <Stack sx={{ width: '100%' }}>
          <TextField
            value={name}
            onChange={onChangeName}
            placeholder={t('common.name')}
            disabled={true}
          />
        </Stack>
        {userAccount.username && (
          <Stack sx={{ width: '100%' }}>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: '16px',
                lineHeight: '140%',
              }}
            >
              Username
            </Typography>
            <CopyableText text={`@${userAccount.username}`} color="green.2" />
          </Stack>
        )}

        <Stack sx={{ width: '100%' }}>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '140%',
            }}
          >
            IDc
          </Typography>
          <CopyableText text={`${userAccount._id}`} color="green.2" />
        </Stack>
        <Divider />

        <Stack
          direction="column"
          gap="12px"
          sx={{
            width: '100%',
          }}
        >
          {/*{userAccount.authorized && (*/}
          <Button
            startIcon={<RefreshIcon />}
            onClick={onRefreshInfo}
            className="blue"
            sx={{
              width: '100%',
              height: '56px',
            }}
            disabled={true}
          >
            {t('projects.screen-telegram-user-account.refreshInfo')}
          </Button>
          <Stack
            sx={{
              width: '100%',
              color: 'blue.2',
            }}
          >
            <Button
              className={'brightGreen'}
              sx={{
                p: '9px 0',
                width: '100%',
                height: '56px',
              }}
              color="inherit"
              variant="contained"
              onClick={onEdit}
              disabled={submitDisabled}
            >
              {t('common.save')}
            </Button>
          </Stack>
          <Stack
            sx={{
              width: '100%',
              color: 'blue.2',
              mt: '20px',
            }}
          >
            <Button
              className="red"
              sx={{
                width: '100%',
                height: '56px',
                p: '9px 0',
              }}
              color="inherit"
              variant="contained"
              onClick={onDeleteClick}
            >
              {t('common.delete')}
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default UserAccountCard;
