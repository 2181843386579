import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Button, Stack, Typography } from '@mui/material';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import getProjectTelegramUserAccesses from '../../api/telegram-accounts/getUserAccesses';
import updateTelegramUserAccesses from '../../api/telegram-accounts/update-accesses';
import { AppContext } from '../../AppContext';
import { NAVBAR_HEIGHT_PX } from '../../common/constants/constants';
import TooltipInfo from '../../common/tooltip/tooltip';
import { ProjectContext } from '../../projects/project.context';
import {
  TelegramMessengerAccess,
  TelegramMessengerMode,
} from '../../telegram-bots/telegram-bot.interface';
import {
  ITelegramUserAccess,
  ITelegramUserAccountSafe,
} from '../telegram-user.interface';

import AccessTableUserBot from './accesses.table';
import UserAccountCard from './user-account.card';

interface UserAccountSettingsProps {
  userAccount: ITelegramUserAccountSafe;
}

export function compareBotAccess(
  access1: ITelegramUserAccess,
  access2: ITelegramUserAccess,
) {
  return access1?.messenger === access2?.messenger;
}

const UserAccountSettings: FC<UserAccountSettingsProps> = ({ userAccount }) => {
  const { project } = useContext(ProjectContext);
  const { triggerSnackbar } = useContext(AppContext);
  const { t } = useTranslation();

  const [accesses, setAccesses] = useState<ITelegramUserAccess[]>([]);
  const [prevAccesses, setPrevAccesses] = useState<ITelegramUserAccess[]>([]);
  const [isEqualAccess, setIsEqualAccess] = useState<boolean>(true);
  const [loggingIn, setLoggingIn] = useState(false);

  const toggleLoggingIn = (loggingIn: boolean) => {
    setLoggingIn(loggingIn);
  };

  const updatePrevAccess = () => {
    getProjectTelegramUserAccesses(userAccount._id).then(
      (res: ITelegramUserAccess[]) => {
        const filteredRes = res.map(
          ({
            _id,
            telegramUserAccount,
            user,
            messenger,
            messengerMode,
            broadcasts,
            channels,
            statistics,
            variables,
            flows,
          }) => ({
            _id,
            telegramUserAccount,
            user,
            messenger,
            messengerMode,
            broadcasts,
            channels,
            statistics,
            variables,
            flows,
          }),
        );
        setPrevAccesses(filteredRes);
      },
    );
  };

  useEffect(() => {
    setIsEqualAccess(
      accesses.every((accessObj, index) =>
        compareBotAccess(accessObj, prevAccesses[index]),
      ),
    );
  }, [accesses, prevAccesses, userAccount, project]);

  useEffect(() => {
    updatePrevAccess();
  }, []);

  useEffect(() => {
    const members = project.members ?? [];
    setAccesses(
      members.map((member) => {
        const userAccess = prevAccesses.find((a) => a.user === member._id);
        return {
          telegramUserAccount: userAccount._id,
          user: member._id,
          messenger: userAccess?.messenger
            ? userAccess.messenger
            : TelegramMessengerAccess.none,
          messengerMode: userAccess?.messengerMode
            ? userAccess.messengerMode
            : TelegramMessengerMode.offline,
          _id: userAccess?._id ?? member._id,
          broadcasts: false,
          channels: false,
          statistics: false,
          variables: false,
          flows: false,
        };
      }) as ITelegramUserAccess[],
    );
  }, [project, prevAccesses]);

  const onUpdateAccesses = () => {
    const filteredAccesses = accesses.map(
      ({
        _id: _unusedId,
        telegramUserAccount: _unusedAccount,
        messengerMode: _unusedMode,
        ...rest
      }) => rest,
    );

    updateTelegramUserAccesses(userAccount._id, filteredAccesses)
      .then(() => {
        updatePrevAccess();
        triggerSnackbar(t('common.changes_saved'), 'success');
      })
      .catch(() => {
        triggerSnackbar(t('triggerSnackbar.errorRefreshBot'), 'error');
      });
  };

  return (
    <Stack
      direction="row"
      alignItems="start"
      justifyContent="center"
      gap="18px"
      sx={{
        padding: '40px 18px',
        width: '100%',
        maxHeight: `calc(100vh - ${NAVBAR_HEIGHT_PX}px)`,
        mt: '80px',
      }}
    >
      <UserAccountCard
        userAccount={userAccount}
        loggingIn={loggingIn}
        toggleLoggingIn={toggleLoggingIn}
      />

      <Stack
        sx={{
          backgroundColor: 'grey.14',
          border: '1px solid',
          borderColor: '#4C4B4C',
          borderRadius: '12px',
          padding: '18px',
          height: '100%',
          maxWidth: '700px',
          width: '100%',
        }}
        gap="10px"
      >
        <Stack flexDirection="row" gap="10px" alignItems="center">
          <Typography
            sx={{
              color: 'grey.5',
              fontWeight: 500,
              fontSize: '18px',
              lineHeight: '150%',
            }}
          >
            {t('projects.screen-new-telegram-bot.tableTitle')}
          </Typography>
          <TooltipInfo
            icon={<InfoOutlinedIcon sx={{ color: 'grey.5' }} />}
            title={t('projects.screen-new-telegram-bot.tableTooltipSetting')}
          />
        </Stack>
        <AccessTableUserBot
          owner={project.owner}
          members={project.members}
          accesses={accesses}
          setAccesses={setAccesses}
        />
        <Button
          variant={'contained'}
          disabled={isEqualAccess}
          className="brightGreen"
          sx={{
            height: '56px',
            fontWeight: 600,
          }}
          onClick={onUpdateAccesses}
        >
          {t('common.save')}
        </Button>
      </Stack>
    </Stack>
  );
};

export default UserAccountSettings;
