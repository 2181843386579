import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { Button, Stack } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import FolderCard from './folder.card';
import { IFlowFolder } from './interfaces';

interface FoldersListProps {
  folders: IFlowFolder[];
  onSelectFolder: (folder: IFlowFolder | null) => void;
  selectedFolder: IFlowFolder | null;
  showAllFlows: () => void;
  onCreateModalFolder: () => void;
  onEditFolder: (folder: IFlowFolder) => void;
  onDeleteFolder: (folder: IFlowFolder) => void;
}

const FoldersList: FC<FoldersListProps> = ({
  folders,
  onSelectFolder,
  selectedFolder,
  showAllFlows,
  onEditFolder,
  onDeleteFolder,
  onCreateModalFolder,
}) => {
  const { t } = useTranslation();

  return (
    <Stack
      direction="row"
      gap="12px"
      sx={{
        flexWrap: 'wrap',
        overflow: 'auto',
        maxHeight: '130px',
        minHeight: folders.length > 3 ? '130px' : '65px',
      }}
    >
      <Stack direction="row" gap="12px">
        <Button
          startIcon={<FormatListBulletedIcon />}
          onClick={showAllFlows}
          className="blue"
          sx={{
            height: '56px',
            padding: '15px 16px',
          }}
        >
          {t('flow.folders.allFlows')}
        </Button>
        <Button
          onClick={onCreateModalFolder}
          className="blue"
          sx={{
            padding: '15px',
          }}
        >
          <svg
            width="26"
            height="24"
            viewBox="0 0 26 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.619 18.9474C13.619 19.3768 13.6686 19.7937 13.7305 20.2105H2.47619C1.11429 20.2105 0 19.0863 0 17.6842V2.52632C0 1.12421 1.1019 0 2.47619 0H9.90476L12.381 2.52632H22.2857C23.6476 2.52632 24.7619 3.65053 24.7619 5.05263V12.3916C23.6724 11.7474 22.4095 11.3684 21.0476 11.3684C16.9495 11.3684 13.619 14.7663 13.619 18.9474ZM22.2857 17.6842V13.8947H19.8095V17.6842H16.0952V20.2105H19.8095V24H22.2857V20.2105H26V17.6842H22.2857Z"
              fill="#FEFEFE"
            />
          </svg>
        </Button>
      </Stack>
      {folders.map((folder) => (
        <FolderCard
          key={folder._id}
          selectedFolder={selectedFolder}
          folder={folder}
          onSelectFolder={onSelectFolder}
          onEdit={onEditFolder}
          onDelete={onDeleteFolder}
        />
      ))}
    </Stack>
  );
};

export default FoldersList;
