import CloseIcon from '@mui/icons-material/Close';
import { Button, Stack, Typography } from '@mui/material';
import { t } from 'i18next';
import React, { FC, useContext, useState } from 'react';

import Input from '../../common/inputs/InputWithIcon';
import ModalBox from '../../common/modal/modal-box';
import { MessengerContext } from '../messenger.context';

interface CreateTagModalProps {
  isCreateTagModalOpen: boolean;
  onClose: () => void;
  onCreateTag: (inputTag: string) => void;
}

const CreateTagModal: FC<CreateTagModalProps> = ({
  isCreateTagModalOpen,
  onClose,
  onCreateTag,
}) => {
  const { tags } = useContext(MessengerContext);

  const [inputTag, setInputTag] = useState('');

  const isCreateTagValid =
    Boolean(inputTag.length <= 0) ||
    Boolean(tags.some((tag) => tag.text === inputTag));

  return (
    <ModalBox
      open={isCreateTagModalOpen}
      onClose={() => {
        onClose();
        setInputTag('');
      }}
      maxWidth="490px"
      sx={{
        p: '24px 32px 40px',
        borderRadius: '12px',
        backgroundColor: 'grey.14',
        position: 'relative',
      }}
    >
      <Stack
        sx={{
          position: 'absolute',
          top: '15px',
          right: '15px',
          cursor: 'pointer',
        }}
        onClick={onClose}
      >
        <CloseIcon />
      </Stack>
      <Stack>
        <Typography
          sx={{
            fontSize: '24px',
            fontWeight: 600,
            textAlign: 'center',
            color: 'grey.1',
          }}
        >
          {t('messenger.createTag')}
        </Typography>
        <Stack
          sx={{
            m: '16px 0',
          }}
        >
          <Input
            value={inputTag}
            placeholder={t('messenger.createTag')}
            handleChangeSearch={(e) => setInputTag(e.target.value.trim())}
            backgroundNarrative={'dark'}
          />
        </Stack>
        <Stack
          direction="row"
          gap="8px"
          sx={{
            mt: '24px',
          }}
        >
          <Button fullWidth className="red" onClick={onClose}>
            {t('common.cancel')}
          </Button>
          <Button
            fullWidth
            className="blue"
            onClick={() => {
              onCreateTag(inputTag);
              setInputTag('');
              onClose();
            }}
            disabled={isCreateTagValid}
          >
            {t('messenger.createTag')}
          </Button>
        </Stack>
      </Stack>
    </ModalBox>
  );
};

export default CreateTagModal;
