import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Button, Chip, Stack, Typography } from '@mui/material';
import { t } from 'i18next';
import React, { ChangeEvent, FC, useContext, useEffect, useState } from 'react';

import { AppContext } from '../../AppContext';
import Input from '../../common/inputs/InputWithIcon';
import ModalBox from '../../common/modal/modal-box';
import ThreeDotsMenu from '../../common/threeDotsMenu/three-dots-menu.component';
import { ITag } from '../../interfaces/common';
import { MessengerContext } from '../messenger.context';

interface EditTagModalProps {
  isEditTagModalOpen: boolean;
  onClose: () => void;
}

const EditTagModal: FC<EditTagModalProps> = ({
  isEditTagModalOpen,
  onClose,
}) => {
  const { tags, updateTag, deleteTag, dialogs } = useContext(MessengerContext);
  const { openConfirmationDialog } = useContext(AppContext);

  const [menuAnchor, setMenuAnchor] = useState<Element | null>(null);
  const [selectedTag, setSelectedTag] = useState<ITag | null>(null);
  const [editTagText, setEditTagText] = useState<string>('');
  const [isEditTag, setIsEditTag] = useState<boolean>(false);
  const [inputTag, setInputTag] = useState<string>('');
  const [filteredTags, setFilteredTags] = useState<ITag[]>([]);

  const onSelectTag = (tag: ITag) => {
    setSelectedTag(tag);
  };

  const onEditTag = () => {
    if (!selectedTag) return;

    setIsEditTag(true);
    setEditTagText(selectedTag?.text);
  };

  const onCloseInsideModal = () => {
    setSelectedTag(null);
    setEditTagText('');
    setIsEditTag(false);
  };

  const onChangeTagText = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEditTagText(value);
  };

  const openProjectMenu = (e: React.SyntheticEvent, tag: ITag) => {
    e.stopPropagation();
    setMenuAnchor(e.currentTarget);
    setSelectedTag(tag);
  };

  const onUpdateTag = () => {
    if (!selectedTag) return;
    updateTag(
      selectedTag._id,
      editTagText,
      dialogs.map((dialog) => dialog._id),
    );
    setInputTag('');
    onCloseInsideModal();
  };

  useEffect(() => {
    setFilteredTags(tags);
  }, [tags]);

  useEffect(() => {
    setFilteredTags(
      tags.filter((tag) => tag.text.includes(inputTag.toLowerCase())),
    );
  }, [inputTag]);
  return (
    <ModalBox
      open={isEditTagModalOpen}
      onClose={() => {
        onClose();
        setInputTag('');
      }}
      maxWidth="490px"
      sx={{
        p: '24px 32px 40px',
        borderRadius: '12px',
        backgroundColor: 'grey.14',
        position: 'relative',
      }}
    >
      <Stack
        sx={{
          position: 'absolute',
          top: '15px',
          right: '15px',
          cursor: 'pointer',
        }}
        onClick={onClose}
      >
        <CloseIcon />
      </Stack>
      <Stack>
        <Typography
          sx={{
            fontSize: '24px',
            fontWeight: 600,
            textAlign: 'center',
            color: 'grey.1',
          }}
        >
          {t('messenger.settingTagsLabel')}
        </Typography>
        <Stack
          flexDirection="row"
          flexWrap="wrap"
          gap="8px"
          sx={{
            maxHeight: '140px',
            overflow: 'auto',
            mt: '16px',
          }}
        >
          {!filteredTags.length && (
            <Typography
              sx={{
                opacity: '0.4',
                textAlign: 'center',
                width: '100%',
                mt: '8px',
              }}
            >
              {t('messenger.noTagsFound')}
            </Typography>
          )}
          {filteredTags.map((tag: ITag) => {
            return (
              <>
                <Chip
                  icon={
                    <MoreHorizIcon
                      sx={{
                        position: 'absolute',
                        right: '7px',
                        alignItems: 'start',
                        height: 'fit-content',
                        padding: '0',
                        cursor: 'pointer',
                        // color: 'red.1',
                        // color: 'grey.10',
                        // '&.MuiSvgIcon-root': { margin: '0', color: 'grey.10' },
                        '&.MuiSvgIcon-root': { margin: '0', color: 'grey.10' },
                      }}
                      onClick={(e) => openProjectMenu(e, tag)}
                    />
                  }
                  key={tag.text}
                  label={tag.text}
                  sx={{
                    paddingRight: '20px',
                    position: 'relative',
                    fontWeight: 600,
                    fontSize: '14px',

                    backgroundColor: 'green.3',
                    color: 'grey.10',
                    border: 'none',
                    borderColor: 'green.2',
                    '&:hover': {
                      backgroundColor: 'green.3',
                      color: 'grey.10',
                      // backgroundColor: isTagInDialog ? 'green.5' : 'green.3',
                      // color: isTagInDialog ? 'grey.1' : 'grey.10',
                      // backgroundColor: 'green.5',
                      // color: 'grey.1',
                    },
                  }}
                  onClick={() => {
                    onSelectTag(tag);
                  }}
                />
                <ThreeDotsMenu
                  open={Boolean(menuAnchor)}
                  onClose={() => {
                    setMenuAnchor(null);
                  }}
                  anchor={menuAnchor}
                  borderColor={'grey.10'}
                  backgroundColor={'grey.15'}
                  borderRadius={'8px'}
                  border={'1px solid'}
                  marginDivider={'0 12px'}
                  menuItems={[
                    [
                      {
                        text: t('common.edit'),
                        textSx: {
                          color: 'grey.secondary',
                        },
                        onClick: () => {
                          onEditTag();
                        },
                        renderIcon: () => <EditIcon />,
                      },
                    ],
                    [
                      {
                        text: t('common.delete'),
                        textSx: { color: 'red.2' },
                        onClick: () => {
                          openConfirmationDialog(
                            `${t('messenger.removeTag')} ${selectedTag?.text}?`,
                            t('common.delete'),
                            () => {
                              if (!selectedTag) return;

                              deleteTag(
                                selectedTag._id,
                                dialogs.map((dialog) => dialog._id),
                              );
                            },
                          );
                        },
                        renderIcon: () => (
                          <DeleteIcon sx={{ color: 'red.2' }} />
                        ),
                      },
                    ],
                  ]}
                />
              </>
            );
          })}
        </Stack>
        <Stack
          direction="row"
          gap="8px"
          sx={{
            mt: '24px',
          }}
        >
          <Button fullWidth className="red" onClick={onClose}>
            {t('common.cancel')}
          </Button>
          <Button fullWidth className="blue" onClick={onClose}>
            {t('common.save')}
          </Button>
        </Stack>
      </Stack>

      <ModalBox
        open={Boolean(isEditTag)}
        onClose={onCloseInsideModal}
        maxWidth="460px"
        sx={{
          padding: '32px 56px',
          backgroundColor: 'grey.14',
          position: 'relative',
        }}
      >
        <Stack
          sx={{
            position: 'absolute',
            top: '15px',
            right: '15px',
            cursor: 'pointer',
          }}
          onClick={onCloseInsideModal}
        >
          <CloseIcon />
        </Stack>
        <Stack>
          <Typography
            sx={{ textAlign: 'center', fontWeight: 600, fontSize: '24px' }}
          >
            {t('messenger.editTag')}
          </Typography>
          <Stack sx={{ m: '16px 0 24px' }}>
            <Input
              value={editTagText}
              handleChangeSearch={onChangeTagText}
              placeholder={t('messenger.editTag')}
              backgroundNarrative="dark"
              withIcon={false}
            />
          </Stack>
          <Stack flexDirection="row" gap="8px">
            <Button
              className="red"
              sx={{
                width: '100%',
              }}
              onClick={onCloseInsideModal}
            >
              {t('common.cancel')}
            </Button>
            <Button
              className="green"
              sx={{ width: '100%' }}
              onClick={onUpdateTag}
              disabled={Boolean(
                selectedTag?.text === editTagText || !editTagText,
              )}
            >
              {t('common.save')}
            </Button>
          </Stack>
        </Stack>
      </ModalBox>
    </ModalBox>
  );
};

export default EditTagModal;
