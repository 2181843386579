import { Table, TableContainer, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { t } from 'i18next';
import React, { FC } from 'react';

import { ITelegramBot } from '../telegram-bot.interface';

import VariableItem from './variable.item';

interface VariableTableProps {
  variables: ITelegramBot['params'];
  openEditVariableModal: (index: number, variable: string) => void;
  onDeleteVariable: (index: number) => void;
}

const VariableTable: FC<VariableTableProps> = ({
  variables,
  openEditVariableModal,
  onDeleteVariable,
}) => {
  return (
    <Paper sx={{ width: '100%' }}>
      <TableContainer sx={{ maxHeight: '100%' }}>
        <Table
          stickyHeader
          sx={{
            borderRadius: '8px 8px 0 0 ',
            backgroundColor: 'grey.12',
            border: '1px solid',
            borderColor: 'grey.10',
            overflow: 'hidden',
          }}
        >
          <TableHead
            sx={{
              height: '45px',
              backgroundColor: 'grey.12',
              width: '100%',
              border: 'none',
            }}
          >
            <TableRow
              sx={{
                position: 'sticky',
                top: '0',
                zIndex: '12',
                width: '100%',
              }}
            >
              <TableCell
                sx={{
                  padding: '12px 24px',
                  backgroundColor: 'grey.12',
                  border: 'none',
                  width: '100%',
                }}
                align={'left'}
                style={{
                  width: '100%',
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: '14px',
                    opacity: '0.6',
                  }}
                >
                  {t('common.title')}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {variables.map((variable, index) => (
              <VariableItem
                key={'list' + index}
                variable={variable}
                index={index}
                openEditVariableModal={openEditVariableModal}
                onDeleteVariable={onDeleteVariable}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default VariableTable;
