import ImageIcon from '@mui/icons-material/Image';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Button, Divider, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { t } from 'i18next';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import refreshBotStatistic from '../../api/bots/refresh-bot-statistic';
import { ProjectContext } from '../project.context';

import ProjectScreens from './project-screens.enum';

const ProjectMain = () => {
  const { project, telegramBots, refetchBots } = useContext(ProjectContext);

  const navigate = useNavigate();
  return (
    <Stack
      gap="16px"
      sx={{
        p: '32px',
        height: '100%',
        overflow: 'auto',
      }}
    >
      <Stack
        gap="18px"
        flexDirection="row"
        flexWrap="wrap"
        sx={{
          margin: 'auto',
        }}
      >
        {telegramBots.map((telegramBot) => {
          return (
            <Stack
              key={telegramBot.bot._id}
              sx={{
                border: '1px solid ',
                borderColor: 'grey.10',
                backgroundColor: 'grey.14',
                borderRadius: '18px',
                padding: '17px',
                width: ' 396px',
              }}
            >
              <Stack
                flexDirection="row"
                alignItems="center"
                sx={{ height: '68px' }}
              >
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    borderRadius: '50%',
                    width: '56px',
                    height: '56px',
                    backgroundColor: 'grey.10',
                    mr: '12px',
                    overflow: 'hidden',
                  }}
                >
                  {telegramBot.bot.avatarUrl ? (
                    <img
                      style={{
                        borderRadius: '50%',
                        width: '56px',
                        height: '56px',
                      }}
                      src={telegramBot.bot.avatarUrl}
                      alt="avatar image"
                    />
                  ) : (
                    <ImageIcon />
                  )}
                </Stack>
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: '24px',
                    lineHeight: '140%',
                    color: 'grey.1',
                  }}
                >
                  {telegramBot.bot.name}
                </Typography>
                <Button
                  className="blue"
                  sx={{
                    width: '56px',
                    height: '56px',
                    minWidth: '56px',
                    marginLeft: ' auto',
                  }}
                  onClick={() =>
                    refreshBotStatistic(telegramBot.bot._id).then(refetchBots)
                  }
                >
                  <RefreshIcon />
                </Button>
              </Stack>
              <Divider
                sx={{
                  m: '12px 0 16px ',
                }}
              />
              <Stack gap="8px">
                <Stack flexDirection="row" gap="6px">
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: '16px',
                      lineHeight: '140%',
                      color: 'grey.1',
                      opacity: '0.6',
                    }}
                  >
                    {t('projects.totalUsersCount')}
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: '16px',
                      lineHeight: '140%',
                      color: 'grey.1',
                    }}
                  >
                    {telegramBot.bot.statistics.totalUsersCount}
                  </Typography>
                </Stack>
                <Stack flexDirection="row" gap="6px">
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: '16px',
                      lineHeight: '140%',
                      color: 'grey.1',
                      opacity: '0.6',
                    }}
                  >
                    {t('projects.numberActiveUsers')}
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: '16px',
                      lineHeight: '140%',
                      color: 'grey.1',
                    }}
                  >
                    {telegramBot.bot.statistics.activeUsersCount}
                  </Typography>
                </Stack>
                <Stack flexDirection="row" gap="6px">
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: '16px',
                      lineHeight: '140%',
                      color: 'grey.1',
                      opacity: '0.6',
                    }}
                  >
                    {t('projects.bannedUsersCount')}
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: '16px',
                      lineHeight: '140%',
                      color: 'grey.1',
                    }}
                  >
                    {telegramBot.bot.statistics.bannedUsersCount}
                  </Typography>
                </Stack>
                <Stack flexDirection="row" gap="6px">
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: '16px',
                      lineHeight: '140%',
                      color: 'grey.1',
                      opacity: '0.6',
                    }}
                  >
                    {t('projects.regsCount')}
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: '16px',
                      lineHeight: '140%',
                      color: 'grey.1',
                    }}
                  >
                    {telegramBot.bot.statistics.regsCount}
                  </Typography>
                </Stack>
                <Stack flexDirection="row" gap="6px">
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: '16px',
                      lineHeight: '140%',
                      color: 'grey.1',
                      opacity: '0.6',
                    }}
                  >
                    {t('projects.depsCount')}
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: '16px',
                      lineHeight: '140%',
                      color: 'grey.1',
                    }}
                  >
                    {telegramBot.bot.statistics.depsCount}
                  </Typography>
                </Stack>
                <Stack flexDirection="row" gap="6px">
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: '16px',
                      lineHeight: '140%',
                      color: 'grey.1',
                      opacity: '0.6',
                    }}
                  >
                    {t('projects.qualsCount')}
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: '16px',
                      lineHeight: '140%',
                      color: 'grey.1',
                    }}
                  >
                    {telegramBot.bot.statistics.qualsCount}
                  </Typography>
                </Stack>
              </Stack>
              <Divider
                sx={{
                  m: '12px 0 16px ',
                }}
              />
              <Stack flexDirection="row" gap="6px">
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: '16px',
                    lineHeight: '140%',
                    color: 'grey.1',
                    opacity: '0.6',
                  }}
                >
                  {t('projects.lastUpdateStats')}
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: '16px',
                    lineHeight: '140%',
                    color: 'grey.1',
                  }}
                >
                  {dayjs(
                    telegramBot.bot.statistics.statisticsUpdatedAt,
                  ).fromNow(true)}
                </Typography>
              </Stack>
              {/*<Button*/}
              {/*  className="green"*/}
              {/*  sx={{*/}
              {/*    mt: '16px',*/}
              {/*  }}*/}
              {/*  onClick={() =>*/}
              {/*    refreshBotStatistic(telegramBot.bot._id).then(refetchBots)*/}
              {/*  }*/}
              {/*>*/}
              {/*  {t('common.refresh')}*/}
              {/*</Button>*/}
              <Button
                className="brightGreen"
                sx={{
                  mt: '16px',
                }}
                onClick={() => {
                  navigate(
                    `/project/${project._id}/${ProjectScreens.bot}/${telegramBot.bot._id}`,
                  );
                }}
              >
                {t('projects.goToBot')}
              </Button>
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  );
};

export default ProjectMain;
