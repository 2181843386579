import AddRoundedIcon from '@mui/icons-material/AddRounded';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Autocomplete,
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { t } from 'i18next';
import React, {
  ChangeEvent,
  FC,
  SyntheticEvent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

import CopyableText from '../../common/copyableText/copyableText';
import { IOSSwitch } from '../../common/iosSwitch';
import { Params } from '../../interfaces/common';
import { convertNumberToMinutes } from '../../utils/convertNumberToMinutes';
import { BotContext } from '../bot.context';
import { IFlowWithActivatorsAndFolder } from '../flows/interfaces';

import {
  IChannelActivatorWithParams,
  UpdateChannelActivatorWithParams,
} from './interfaces';

const MAX_NAME_LENGTH = 50;

interface CreateChannelActivatorProps {
  onCreateActivator: (
    newChannelActivator: Omit<
      IChannelActivatorWithParams,
      | 'createdAt'
      | 'updatedAt'
      | '_id'
      | 'channel'
      | 'inviteLink'
      | 'hidden'
      | 'joinRequestsCount'
    >,
  ) => void;
  isCopyActivator: boolean;
  channelFlows: IFlowWithActivatorsAndFolder[];
  creatingNewActivator: boolean;
  selectedActivator: IChannelActivatorWithParams | null;
  onEditActivator: (
    activatorToUpdate: UpdateChannelActivatorWithParams,
  ) => void;
  onResetSelectedActivator: () => void;
}

const CreateEditChannelActivator: FC<CreateChannelActivatorProps> = ({
  onCreateActivator,
  isCopyActivator,
  onResetSelectedActivator,
  channelFlows,
  onEditActivator,
  creatingNewActivator,
  selectedActivator,
}) => {
  const { bot } = useContext(BotContext);

  const navigate = useNavigate();

  const [name, setName] = useState<string>('');
  const [joinFlow, setJoinFlow] = useState<string | null>(null);
  const [leaveFlow, setLeaveFlow] = useState<string | null>(null);
  const [kickFlow, setKickFlow] = useState<string | null>(null);
  const [isMembershipTemporary, setIsMembershipTemporary] =
    useState<boolean>(false);
  const [acceptRequests, setAcceptRequests] = useState<boolean>(true);
  const [params, setParams] = useState<Params>({});
  const [inputValueKey, setInputValueKey] = useState<Record<string, string>>(
    {},
  );

  const [expiresInDays, setExpiresInDays] = useState<number>(0);
  const [expiresInHours, setExpiresInHours] = useState<number>(0);
  const [expiresInMinutes, setExpiresInMinutes] = useState<number>(0);

  const membershipExpiresInMinutes =
    expiresInMinutes + expiresInHours * 60 + expiresInDays * 1440;

  const expireValid = !isNaN(membershipExpiresInMinutes);

  const valid = useMemo(
    () =>
      Boolean(name) &&
      Boolean(
        selectedActivator?.params !== params ||
          selectedActivator?.name !== name ||
          selectedActivator?.joinFlow !== joinFlow ||
          selectedActivator?.kickFlow !== kickFlow ||
          selectedActivator?.membershipExpiresInMinutes !==
            membershipExpiresInMinutes ||
          selectedActivator?.leaveFlow !== leaveFlow ||
          selectedActivator?.acceptRequests !== acceptRequests,
      ) &&
      Object.entries(params).every(
        ([key, value]) => Boolean(key) && Boolean(value),
      ) &&
      expireValid,
    [
      name,
      params,
      expireValid,
      selectedActivator,
      joinFlow,
      kickFlow,
      membershipExpiresInMinutes,
      leaveFlow,
      acceptRequests,
    ],
  );

  const onAddParam = () => {
    setParams((prev) => ({ ...prev, '': '' }));
  };

  const onParamChange = (
    key: string,
    fieldName: 'key' | 'value',
    newValue: string | null,
  ) => {
    setParams((prev) => {
      const updatedParams = { ...prev };
      if (newValue === null) {
        const value = updatedParams[key];
        delete updatedParams[key];
        updatedParams[''] = value;
      } else {
        if (fieldName === 'key') {
          const value = updatedParams[key];
          delete updatedParams[key];
          updatedParams[newValue] = value;

          setInputValueKey((prev) => {
            const updatedInputValues = { ...prev };
            delete updatedInputValues[key];
            updatedInputValues[newValue] = '';
            return updatedInputValues;
          });
        } else {
          updatedParams[key] = newValue;
        }
      }
      return updatedParams;
    });
  };

  const onParamDelete = (key: string) => {
    setParams((prev) => {
      const updatedParams = { ...prev };
      delete updatedParams[key];
      return updatedParams;
    });

    setInputValueKey((prev) => {
      const updatedInputValues = { ...prev };
      delete updatedInputValues[key];
      return updatedInputValues;
    });
  };
  const onNameInput = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value.length <= MAX_NAME_LENGTH) {
      setName(value);
    }
  };

  const onDaysInput = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const number = parseInt(value);

    if (!isNaN(number)) {
      setExpiresInDays(number);
    } else {
      setExpiresInDays(0);
    }
  };

  const onHoursInput = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const number = parseInt(value);

    if (!isNaN(number)) {
      setExpiresInHours(number);
    } else {
      setExpiresInHours(0);
    }
  };

  const onMinutesInput = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const number = parseInt(value);

    if (!isNaN(number)) {
      setExpiresInMinutes(number);
    } else {
      setExpiresInMinutes(0);
    }
  };

  const handleChangeJoinFlow = (e: SelectChangeEvent) => {
    const value = e.target.value;
    setJoinFlow(value);
  };

  const handleChangeLeaveFlow = (e: SelectChangeEvent) => {
    const value = e.target.value;
    setLeaveFlow(value);
  };

  const handleChangeKickFlow = (e: SelectChangeEvent) => {
    const value = e.target.value;
    setKickFlow(value);
  };

  const onChangeTemporaryMembership = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const checked = event.target.checked;
    setIsMembershipTemporary(checked);
    if (!Boolean(checked)) {
      setKickFlow(null);
      setExpiresInDays(0);
      setExpiresInHours(0);
      setExpiresInMinutes(0);
    }
  };

  const onSave = () => {
    if (creatingNewActivator || isCopyActivator) {
      onCreateActivator({
        params,
        name,
        joinFlow,
        leaveFlow,
        kickFlow,
        acceptRequests,
        membershipExpiresInMinutes,
      });
      onResetSelectedActivator();
    } else {
      onEditActivator({
        name,
        params,
        joinFlow,
        leaveFlow,
        acceptRequests,
        kickFlow,
        membershipExpiresInMinutes,
      });
    }
    setJoinFlow(null);
    setLeaveFlow(null);
    setKickFlow(null);
    setAcceptRequests(true);
    setIsMembershipTemporary(false);
    setExpiresInDays(0);
    setExpiresInHours(0);
    setExpiresInMinutes(0);
    setName('');
    setParams({});
  };

  useEffect(() => {
    if (selectedActivator) {
      const days = convertNumberToMinutes(
        selectedActivator?.membershipExpiresInMinutes ?? 0,
      ).days;
      const hours = convertNumberToMinutes(
        selectedActivator?.membershipExpiresInMinutes ?? 0,
      ).hours;
      const minutes = convertNumberToMinutes(
        selectedActivator?.membershipExpiresInMinutes ?? 0,
      ).minutes;
      setName(selectedActivator?.name as string);
      setParams((selectedActivator?.params as Params) ?? {});
      setJoinFlow(selectedActivator?.joinFlow ?? null);
      setLeaveFlow(selectedActivator?.leaveFlow ?? null);
      setKickFlow(selectedActivator?.kickFlow ?? null);
      setAcceptRequests(selectedActivator?.acceptRequests ?? true);
      setExpiresInDays(days);
      setExpiresInHours(hours);
      setExpiresInMinutes(minutes);
      if (!days && !hours && !minutes) {
        setIsMembershipTemporary(false);
      } else {
        setIsMembershipTemporary(true);
      }
    } else {
      setName('');
      setJoinFlow(null);
      setLeaveFlow(null);
      setKickFlow(null);
      setAcceptRequests(true);
      setParams({});
      setExpiresInDays(0);
      setExpiresInHours(0);
      setExpiresInMinutes(0);
      setIsMembershipTemporary(false);
    }
  }, [selectedActivator, isCopyActivator, creatingNewActivator]);

  const getFilteredOptions = (selectedKey: string) => {
    const selectedKeys = Object.keys(params);
    return bot.params.filter(
      (param) => param === selectedKey || !selectedKeys.includes(param),
    );
  };
  return (
    <Stack
      sx={{
        width: '100%',
        height: '100%',
      }}
    >
      <Stack
        sx={{
          overflow: 'auto',
          maxHeight: '80%',
        }}
      >
        <Stack direction="row" alignItems="center" gap="15px">
          <TextField
            placeholder={t('channels.channelActivators.namePlaceholder')}
            value={name}
            onChange={onNameInput}
            sx={{
              width: '100%',
            }}
          />
        </Stack>

        {selectedActivator && !isCopyActivator && (
          <Stack
            direction="row"
            alignItems="center"
            gap="15px"
            sx={{
              mt: '10px',
            }}
          >
            <Typography sx={{ width: '100px' }}>
              {t('channels.channelActivators.inviteLink')}
            </Typography>
            <CopyableText text={selectedActivator.inviteLink} />
          </Stack>
        )}
        <Stack
          flexDirection="row"
          alignItems="center"
          gap="10px"
          sx={{ mt: '12px' }}
        >
          <FormControl fullWidth>
            <InputLabel>{t('common.subscriptionFlow')}</InputLabel>
            <Select
              sx={{
                '.MuiSelect-select': {
                  backgroundColor: 'grey.15',
                  py: '14px',
                },
              }}
              label={t('common.subscriptionFlow')}
              value={joinFlow ?? ''}
              onChange={handleChangeJoinFlow}
            >
              {channelFlows.map(({ _id, name }) => (
                <MenuItem key={_id} value={_id}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            className="red"
            sx={{
              minWidth: '40px',
              width: '40px',
              height: '56px',
            }}
            onClick={() => setJoinFlow(null)}
          >
            <DeleteIcon sx={{ color: 'grey.1' }} />
          </Button>
        </Stack>
        <Stack
          flexDirection="row"
          alignItems="center"
          gap="10px"
          sx={{ mt: '12px' }}
        >
          <FormControl fullWidth>
            <InputLabel>{t('common.unsubscribeFlow')}</InputLabel>
            <Select
              sx={{
                '.MuiSelect-select': {
                  backgroundColor: 'grey.15',
                  py: '14px',
                },
              }}
              label={t('common.unsubscribeFlow')}
              value={leaveFlow ?? ''}
              onChange={handleChangeLeaveFlow}
            >
              {channelFlows.map(({ _id, name }) => (
                <MenuItem key={_id} value={_id}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            className="red"
            sx={{
              minWidth: '40px',
              width: '40px',
              height: '56px',
            }}
            onClick={() => setLeaveFlow(null)}
          >
            <DeleteIcon sx={{ color: 'grey.1' }} />
          </Button>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          gap="10px"
          sx={{ m: '12px 5px 0' }}
        >
          <FormControlLabel
            sx={{
              '& .MuiTypography-root': { fontSize: '14px', fontWeight: 500 },
            }}
            control={
              <IOSSwitch
                sx={{ m: 1 }}
                checked={isMembershipTemporary}
                onChange={onChangeTemporaryMembership}
              />
            }
            label={t('channels.automaticUnsubscribe')}
          />
          <FormControlLabel
            sx={{
              '& .MuiTypography-root': { fontSize: '14px', fontWeight: 500 },
            }}
            control={
              <IOSSwitch
                sx={{ m: 1 }}
                checked={acceptRequests}
                onChange={(event) => setAcceptRequests(event.target.checked)}
              />
            }
            label={t('channels.channelActivators.acceptRequest')}
          />
        </Stack>

        {isMembershipTemporary && (
          <Stack
            flexDirection="row"
            alignItems="center"
            gap="10px"
            sx={{ mt: '12px' }}
          >
            <FormControl fullWidth>
              <InputLabel>{t('common.removalFlow')}</InputLabel>
              <Select
                sx={{
                  '.MuiSelect-select': {
                    backgroundColor: 'grey.15',
                    py: '14px',
                  },
                }}
                label={t('common.removalFlow')}
                value={kickFlow ?? ''}
                onChange={handleChangeKickFlow}
              >
                {channelFlows.map(({ _id, name }) => (
                  <MenuItem key={_id} value={_id}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button
              className="red"
              sx={{
                minWidth: '40px',
                width: '40px',
                height: '56px',
              }}
              onClick={() => setKickFlow(null)}
            >
              <DeleteIcon sx={{ color: 'grey.1' }} />
            </Button>
          </Stack>
        )}

        {isMembershipTemporary && (
          <Stack sx={{ mt: '12px' }} direction="column" gap="10px">
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: '14px',
              }}
            >
              {t('channels.expiresIn')}:
            </Typography>
            <Stack direction="row" gap="5px">
              <TextField
                value={expiresInDays ? expiresInDays : ''}
                onChange={onDaysInput}
                sx={{
                  width: '120px',
                  height: '100%',
                  '.MuiInputBase-root': {
                    paddingRight: '17px',
                    borderRadius: '6px',
                  },
                }}
                placeholder={'0'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      sx={{
                        width: '50%',
                        marginLeft: '17px',
                        justifyContent: 'center',
                        '& .MuiTypography-root': {
                          fontWeight: 500,
                          color: 'grey.1',
                          fontSize: '16px',
                        },
                      }}
                      position="end"
                    >
                      {t('common.days')}
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                sx={{
                  width: '120px',
                  height: '100%',
                  '.MuiInputBase-root': {
                    paddingRight: '11px',
                    borderRadius: '6px',
                  },
                }}
                placeholder={'0'}
                value={expiresInHours ? expiresInHours : ''}
                onChange={onHoursInput}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      sx={{
                        width: '50%',
                        marginLeft: '11px',
                        justifyContent: 'center',
                        '& .MuiTypography-root': {
                          fontWeight: 500,
                          color: 'grey.1',
                          fontSize: '16px',
                        },
                      }}
                      position="end"
                    >
                      {t('common.hours')}
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                value={expiresInMinutes ? expiresInMinutes : ''}
                onChange={onMinutesInput}
                sx={{
                  width: '120px',
                  height: '100%',
                  '.MuiInputBase-root': {
                    paddingRight: '6px',
                    borderRadius: '6px',
                  },
                }}
                placeholder={'0'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      sx={{
                        width: '50%',
                        marginLeft: '6px',
                        justifyContent: 'center',
                        '& .MuiTypography-root': {
                          fontWeight: 500,
                          color: 'grey.1',
                          fontSize: '16px',
                        },
                      }}
                      position="end"
                    >
                      {t('common.minutes')}
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>
          </Stack>
        )}

        {Boolean(params?.length) && (
          <Typography
            sx={{
              mt: '15px',
              fontSize: '18px',
              fontWeight: 600,
              textAlign: 'center',
            }}
          >
            {t('common.parameters')}
          </Typography>
        )}
        <Stack
          sx={{
            mt: '15px',
          }}
        >
          {Object.entries(params).map(([key, value], index) => (
            <Stack
              key={'param' + index}
              direction="row"
              alignItems="center"
              gap="15px"
              sx={{
                mt: index && '10px',
              }}
            >
              <Autocomplete
                sx={{
                  width: '100%',
                  maxWidth: '280px',
                  '& .MuiInputBase-root': {
                    backgroundColor: 'grey.15',
                    height: '52px',
                    p: '0',
                    pl: '12px',
                  },
                }}
                value={key}
                onInputChange={(event, newInputValue) => {
                  setInputValueKey((prev) => ({
                    ...prev,
                    [key]: newInputValue,
                  }));
                }}
                onChange={(e: SyntheticEvent, newValue: string | null) => {
                  onParamChange(key, 'key', newValue);
                }}
                inputValue={inputValueKey[key] || ''}
                options={getFilteredOptions(key)}
                renderInput={(params) => (
                  <TextField {...params} placeholder={t('common.key')} />
                )}
                noOptionsText={
                  <Stack sx={{ textAlign: 'center' }}>
                    <Typography
                      sx={{ mt: '15px', fontWeight: 500, fontSize: '14px' }}
                    >
                      {t('common.notFoundVariable')}
                    </Typography>
                    <Button
                      onClick={() =>
                        navigate(
                          `/project/${bot?.project}/bot/${bot?._id}/variables`,
                        )
                      }
                      sx={{ mt: '15px', fontWeight: 500, fontSize: '14px' }}
                    >
                      {t('common.createVariable')}
                    </Button>
                  </Stack>
                }
              />
              <TextField
                sx={{
                  width: '100%',
                  maxWidth: '280px',
                }}
                placeholder={t('common.value')}
                value={value}
                onChange={(e) => {
                  onParamChange(key, 'value', e.target.value.trim());
                }}
              />
              <Button
                className="red"
                sx={{
                  minWidth: '40px',
                  width: '40px',
                  height: '56px',
                }}
                onClick={() => {
                  onParamDelete(key);
                }}
              >
                <DeleteIcon sx={{ color: 'grey.1' }} />
              </Button>
            </Stack>
          ))}
        </Stack>

        <Button
          sx={{
            textTransform: 'none',
            color: 'blue.2',
            mt: '10px',
          }}
          onClick={onAddParam}
        >
          {t('channels.channelActivators.addParam')}
          <AddRoundedIcon
            sx={{
              ml: '5px',
            }}
          />
        </Button>
      </Stack>
      <Button
        className="blue"
        sx={{
          mt: 'auto',
        }}
        onClick={onSave}
        disabled={!valid}
      >
        {t('channels.channelActivators.save')}
      </Button>
    </Stack>
  );
};

export default CreateEditChannelActivator;
