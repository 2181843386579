import CloseIcon from '@mui/icons-material/Close';
import { Button, Chip, Stack, Typography } from '@mui/material';
import { t } from 'i18next';
import React, { FC, useContext, useState } from 'react';

import ModalBox from '../../common/modal/modal-box';
import { ITag } from '../../interfaces/common';
import { MessengerContext } from '../messenger.context';

interface AddTagModalProps {
  isAddTagModalOpen: boolean;
  dialogTags: ITag[];
  onClose: () => void;
  dialogId: string;
}

const AddTagModal: FC<AddTagModalProps> = ({
  isAddTagModalOpen,
  dialogTags,
  onClose,
  dialogId,
}) => {
  const { tags, assignTags } = useContext(MessengerContext);

  const [selectedTag, setSelectedTag] = useState<ITag[]>([]);

  const handleSelectedTag = (tag: ITag) => {
    const isInDialogTags = dialogTags.some(
      (dialogTag) => dialogTag.text === tag.text,
    );
    const isInSelectedTags = selectedTag.some(
      (selectTag) => selectTag.text === tag.text,
    );

    if (!isInDialogTags && !isInSelectedTags) {
      setSelectedTag((prev) => [...prev, tag]);
    }
  };

  const addTagsToDialog = () => {
    assignTags(dialogId, [
      ...dialogTags.map((tag) => tag._id),
      ...selectedTag.map((tag) => tag._id),
    ]);
    setSelectedTag([]);
    onClose();
  };

  return (
    <ModalBox
      open={isAddTagModalOpen}
      onClose={() => {
        onClose();
        setSelectedTag([]);
      }}
      maxWidth="490px"
      sx={{
        p: '24px 32px 40px',
        borderRadius: '12px',
        backgroundColor: 'grey.14',
        position: 'relative',
      }}
    >
      <Stack
        sx={{
          position: 'absolute',
          top: '15px',
          right: '15px',
          cursor: 'pointer',
        }}
        onClick={() => {
          onClose();
          setSelectedTag([]);
        }}
      >
        <CloseIcon />
      </Stack>
      <Stack>
        <Typography
          sx={{
            fontSize: '24px',
            fontWeight: 600,
            textAlign: 'center',
            color: 'grey.1',
          }}
        >
          {t('messenger.addTag')}
        </Typography>
        <Stack
          direction="row"
          flexWrap="wrap"
          gap="8px"
          sx={{
            m: '16px 0',
          }}
        >
          {tags.map((tag: ITag) => {
            const isTagInDialog = dialogTags.some(
              (dialogTag) => dialogTag.text === tag.text,
            );
            const choosenTag = selectedTag.some(
              (selectTag) => selectTag.text === tag.text,
            );
            return (
              <>
                <Chip
                  key={tag.text}
                  label={tag.text}
                  sx={{
                    backgroundColor: isTagInDialog
                      ? 'green.5'
                      : choosenTag
                      ? 'blue.2'
                      : 'green.3',
                    minWidth: '68px',
                    position: 'relative',
                    fontWeight: 600,
                    fontSize: '14px',
                    color: isTagInDialog
                      ? 'grey.1'
                      : choosenTag
                      ? 'grey.1'
                      : 'grey.10',
                    border: isTagInDialog ? '2px solid' : 'none',
                    borderColor: 'green.2',
                    '&:hover': {
                      backgroundColor: isTagInDialog ? 'green.5' : 'green.3',
                      color: isTagInDialog ? 'grey.1' : 'grey.10',
                    },
                  }}
                  onClick={() => {
                    handleSelectedTag(tag);
                  }}
                />
              </>
            );
          })}
        </Stack>
        <Stack
          direction="row"
          gap="8px"
          sx={{
            mt: '24px',
          }}
        >
          <Button
            fullWidth
            className="red"
            onClick={() => {
              onClose();
              setSelectedTag([]);
            }}
          >
            {t('common.cancel')}
          </Button>
          <Button
            fullWidth
            className="blue"
            onClick={addTagsToDialog}
            disabled={!selectedTag.length}
          >
            {t('messenger.addTag')}
          </Button>
        </Stack>
      </Stack>
    </ModalBox>
  );
};

export default AddTagModal;
