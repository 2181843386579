import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {
  Box,
  Container,
  IconButton,
  Modal,
  Stack,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { t } from 'i18next';
import React, { FC, useState } from 'react';

import FormattedText from '../../common/formatted-text';
import GridImages from '../../common/grid-images/grid-images';
import TelegramButtons from '../../common/telegram/telegram.buttons';
import {
  IMedia,
  TelegramButton,
  TelegramMessageDirection,
  TelegramMessageReason,
} from '../../interfaces/common';
import truncate from '../../utils/truncate';

import MediaSlider from './MediaSlider';

const getMessageStyle = (direction: TelegramMessageDirection) => {
  switch (direction) {
    case TelegramMessageDirection.income: {
      return {
        align: 'start',
        timeOffset: {
          bottom: '4px',
          right: '5px',
        },
        backgroundColor: 'grey.13',
        padding: '4px 4px 15px 4px',
      };
    }
    case TelegramMessageDirection.outcome: {
      return {
        align: 'end',
        backgroundColor: 'blue.5',
        timeOffset: {
          bottom: '4px',
          right: '5px',
        },
        padding: '4px 4px 15px 4px',
      };
    }

    default: {
      return {
        align: 'center',
        timeOffset: {
          bottom: '10px',
          right: '-45px',
        },
        padding: '10px',
      };
    }
  }
};

const MediaContainer = ({
  media,
  onOpenMediaModal,
}: {
  media: IMedia[];
  onOpenMediaModal: (index: number) => void;
}) => {
  return (
    <Box sx={{ height: '100%' }}>
      <Container maxWidth="lg" sx={{ '&.MuiContainer-root': { p: 0 } }}>
        <GridImages media={media} onOpenMediaModal={onOpenMediaModal} />
      </Container>
    </Box>
  );
};

interface MediaMessageComponentProps {
  senderName?: string;
  direction: TelegramMessageDirection;
  reason: TelegramMessageReason;
  text: string;
  translation: string;
  media: IMedia[];
  date: Date;
  edited: boolean;
  buttons: TelegramButton[][];
  deleted: boolean | undefined;
  replyText: string;
  replyMedia: string;
  openContextMenu: (e: React.MouseEvent) => void;
}

const MediaMessageComponent: FC<MediaMessageComponentProps> = ({
  senderName,
  text,
  media,
  date,
  edited,
  buttons,
  deleted,
  replyText,
  replyMedia,
  direction,
  translation,
  openContextMenu,
}) => {
  const [openMediaModal, setOpenMediaModal] = useState<boolean>(false);
  const [selectedMediaIndex, setSelectedMediaIndex] = useState<number>(0);

  const { align, timeOffset, padding, backgroundColor } =
    getMessageStyle(direction);

  const displayButtons = Boolean(buttons?.length);

  const onOpenMediaModal = (index: number) => {
    setSelectedMediaIndex(index);
    setOpenMediaModal(true);
  };

  const handleSlideChange = (index: number) => {
    setSelectedMediaIndex(index);
  };

  return (
    <Stack
      alignSelf={align}
      direction="row"
      alignItems="center"
      onContextMenu={openContextMenu}
      sx={{
        maxWidth: '440px',
        minWidth: displayButtons ? '440px' : '110px',
      }}
    >
      {!deleted && (
        <IconButton
          onClick={openContextMenu}
          sx={{
            order: direction === TelegramMessageDirection.outcome ? '1' : '2',
            transform: 'rotate(90deg)',
            color: 'grey.5',
            alignItems: 'start',
            height: 'fit-content',
          }}
        >
          <MoreHorizIcon />
        </IconButton>
      )}
      <Stack
        sx={{
          width: '100%',
          order: direction === TelegramMessageDirection.outcome ? '2' : '1',
          position: 'relative',
          '&:hover': {
            '.controlIcon': {
              opacity: 1,
            },
          },
        }}
      >
        {Boolean(translation) && (
          <Stack
            className="controlIcon"
            sx={{
              position: 'absolute',
              top: '0',
              left: '0',
              width: '100%',
              height: '100%',
              opacity: 0,
              cursor: 'pointer',
              transition: 'opacity 0.1s ease',
              backgroundColor: deleted ? 'red.5' : backgroundColor,
              borderRadius: '6px',
              zIndex: 1,
              border:
                direction !== TelegramMessageDirection.outcome
                  ? '1px solid'
                  : 'none',
              borderColor:
                direction !== TelegramMessageDirection.outcome
                  ? 'grey.15'
                  : 'none',
              padding: '10px',
            }}
          >
            <FormattedText
              text={translation}
              style={{
                fontWeight: 500,
                fontSize: '14px',
                color: deleted ? '#A1A1A1' : '#FEFEFE',
              }}
            />
          </Stack>
        )}
        <Stack
          sx={{
            padding: media.length > 0 ? '4px 4px 15px' : padding,
            backgroundColor: deleted ? 'red.5' : backgroundColor,
            border:
              media.length > 0 || direction === TelegramMessageDirection.outcome
                ? 'none'
                : '1px solid',
            borderColor:
              direction !== TelegramMessageDirection.outcome
                ? 'grey.12'
                : 'transparent',
            borderRadius: '6px',
            minWidth: '100px',
            position: 'relative',
          }}
        >
          {replyText && !replyMedia && (
            <Stack
              direction="row"
              alignItems="center"
              gap="5px"
              sx={{
                p: '2px',
                borderRadius: '4px',
                backgroundColor: 'blue.6',
              }}
            >
              <Stack
                sx={{
                  height: '25px',
                  width: '2px',
                  borderRadius: '2px',
                  backgroundColor: 'blue.2',
                }}
              />
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: '14px',
                  color: 'grey.secondary',
                }}
              >
                {truncate(replyText, 40)}
              </Typography>
            </Stack>
          )}
          {replyMedia && (
            <Stack
              direction="row"
              alignItems="center"
              gap="5px"
              sx={{
                p: '2px',
                borderRadius: '4px',
                backgroundColor: 'blue.6',
              }}
            >
              <Stack
                sx={{
                  height: '25px',
                  width: '2px',
                  borderRadius: '2px',
                  backgroundColor: 'blue.2',
                }}
              />
              <img
                src={replyMedia}
                style={{ width: '25px', height: '25px' }}
                alt="alt"
              />
              {replyText && (
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: '14px',
                    color: 'grey.secondary',
                  }}
                >
                  {truncate(replyText, 40)}
                </Typography>
              )}
            </Stack>
          )}
          <Typography
            alignItems="center"
            gap="4px"
            sx={{
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'end',
              position: 'absolute',
              width: 'max-content',
              height: 'max-content',
              bottom: timeOffset.bottom,
              right: timeOffset.right,
              fontSize: '12px',
              color: deleted ? 'grey.secondary' : 'grey.1',
              opacity: media?.length > 1 ? 0 : 1,
              ':hover': {
                opacity: 1,
              },
            }}
          >
            {edited && !deleted && (
              <Typography
                component="span"
                sx={{
                  color: 'grey.5',
                  fontSize: '10px',
                  fontWeight: '500',
                  lineHeight: '130%',
                }}
              >
                {t('common.edited')}
              </Typography>
            )}
            <Typography sx={{ fontWeight: 600, fontSize: '10px' }}>
              {dayjs(date).format('HH:mm')}
            </Typography>
            {senderName && (
              <>
                <InfoIcon
                  sx={{
                    fontSize: '15px',
                    '&:hover ~ .info-content': {
                      opacity: 1,
                      transform: 'scale(1)',
                    },
                  }}
                />
                <Stack
                  className="info-content"
                  sx={{
                    position: 'absolute',
                    top: '-175%',
                    right: 0,
                    zIndex: 3,
                    opacity: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minWidth: '134px',
                    p: '6px 10px',
                    borderRadius: '12px',
                    border: '1px solid',
                    borderColor: 'grey.5',
                    backgroundColor: 'grey.10',
                    transform: 'scale(0.8)',
                    transition:
                      'opacity 0.1s ease-in-out, transform 0.3s ease-in-out',
                  }}
                >
                  <Typography
                    sx={{
                      color: 'grey.5',
                      fontFamily: 'Gilroy',
                      fontSize: '10px',
                      fontWeight: 500,
                      lineHeight: '130%',
                    }}
                  >
                    {senderName}
                  </Typography>
                </Stack>
              </>
            )}
          </Typography>
          <>
            {Boolean(media?.length) && (
              <MediaContainer
                media={media}
                onOpenMediaModal={onOpenMediaModal}
              />
            )}
            {Boolean(text?.length) && (
              <Stack
                sx={{
                  padding: media?.length && text ? '5px' : '0',
                  m: '4px 6px',
                }}
              >
                <FormattedText
                  text={text}
                  style={{
                    fontWeight: 500,
                    fontSize: '14px',
                    color: deleted ? '#A1A1A1' : '#FEFEFE',
                  }}
                />
              </Stack>
            )}
          </>
        </Stack>
        {displayButtons && (
          <Stack
            sx={{
              mt: '5px',
            }}
          >
            <TelegramButtons buttons={buttons} />
          </Stack>
        )}
      </Stack>
      <Modal
        open={openMediaModal}
        onClose={() => setOpenMediaModal(false)}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'transparent',
          backdropFilter: 'blur(10px)',
        }}
      >
        <Stack
          onClick={(e) => e.stopPropagation()}
          sx={{
            padding: '0',
            width: '100%',
            maxWidth: '80vw',
            position: 'relative',
          }}
        >
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              position: 'absolute',
              cursor: 'pointer',
              top: '-10vh',
              left: '-10vw',
              width: '100vw',
              height: '9vh',
              opacity: '0.5',
              '&:hover': { opacity: '1' },
              transition: 'all 0.3s',
            }}
            onClick={() => setOpenMediaModal(false)}
          >
            <CloseIcon />
          </Stack>
          <MediaSlider
            media={media}
            selectedMediaIndex={selectedMediaIndex}
            handleSlideChange={handleSlideChange}
          />
        </Stack>
      </Modal>
    </Stack>
  );
};

export default MediaMessageComponent;
