import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import SmsIcon from '@mui/icons-material/Sms';
import SubdirectoryArrowRightRoundedIcon from '@mui/icons-material/SubdirectoryArrowRightRounded';
import { Stack } from '@mui/material';
import { t } from 'i18next';
import React, { ReactNode, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import WithBackgroundPattern from '../common/withBgPattern';
import { BotContext } from '../telegram-bots/bot.context';
import { TelegramMessengerAccess } from '../telegram-bots/telegram-bot.interface';
import { UserAccountContext } from '../telegram-user-accounts/user-account.context';
import useTelegramAccess from '../utils/hooks/useTelegramAccess';

import DialogsTab from './dialogs.tab';
import MessengerDrawer from './messenger-drawer';
import { MessengerContext } from './messenger.context';
import ModeControl, { getModeProps } from './mode.control';
import TemplatesTab from './templates.tab';

export interface IMessengerTab {
  label?: string;
  icon?: ReactNode;
  disabled?: boolean;
  component?: ReactNode;
  control?: ReactNode;
}

const MessengerPage = ({ isBot = false }: { isBot?: boolean }) => {
  const { access: botAccess } = useContext(BotContext);
  const { access: userAccountAccess } = useContext(UserAccountContext);
  const { setMessengerMode } = useContext(MessengerContext);

  const access = useTelegramAccess(botAccess, userAccountAccess, isBot);

  const navigate = useNavigate();

  const [readDialogs] = useState(
    access?.messenger === TelegramMessengerAccess.manager,
  );

  const [currentTabIndex, setCurrentTabIndex] = useState(1);

  const isAdmin = access.messenger === TelegramMessengerAccess.admin;

  const TABS: IMessengerTab[] = [
    {
      label: t('messenger.tabs.back'),
      icon: (
        <SubdirectoryArrowRightRoundedIcon
          sx={{
            transform: 'rotate(180deg)',
          }}
        />
      ),
    },
    {
      label: t('messenger.tabs.dialogs'),
      icon: <SmsIcon />,
      component: <DialogsTab readDialogs={readDialogs} />,
    },
    {
      label: t('messenger.templatesLabel'),
      icon: <DescriptionOutlinedIcon />,
      component: <TemplatesTab />,
    },
  ];

  if (!isAdmin) {
    TABS.push({
      control: (
        <ModeControl
          mode={access?.messengerMode}
          handleChangeMode={setMessengerMode}
        />
      ),
      icon: (
        <Stack
          sx={{
            position: 'relative',
          }}
        >
          <PersonRoundedIcon />
          <Stack
            sx={{
              position: 'absolute',
              bottom: 2,
              right: 2,
              width: '7px',
              height: '7px',
              borderRadius: '50%',
              backgroundColor:
                access && getModeProps(access?.messengerMode).color,
            }}
          />
        </Stack>
      ),
    });
  }

  const onChangeTab = (_: React.SyntheticEvent, newValue: number) => {
    if (newValue === 0) {
      navigate(-1);
      return;
    }

    if (TABS[newValue].disabled || Boolean(TABS[newValue].control)) {
      return;
    }
    setCurrentTabIndex(newValue);
  };

  return (
    <WithBackgroundPattern>
      <Stack direction="row">
        <MessengerDrawer
          currentTabIndex={currentTabIndex}
          tabs={TABS}
          onTabChange={onChangeTab}
          mode={!isAdmin ? access?.messengerMode : ''}
        />
        <Stack
          sx={{
            width: '100%',
          }}
        >
          {TABS[currentTabIndex]?.component}
        </Stack>
      </Stack>
    </WithBackgroundPattern>
  );
};

export default MessengerPage;
