import CallSplitIcon from '@mui/icons-material/CallSplit';
import CampaignRoundedIcon from '@mui/icons-material/CampaignRounded';
import ChatRoundedIcon from '@mui/icons-material/ChatRounded';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import LinkIcon from '@mui/icons-material/Link';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import TrendingUpRoundedIcon from '@mui/icons-material/TrendingUpRounded';
import { Stack } from '@mui/material';
import { t } from 'i18next';
import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import VariablesIcon from '../assets/icons/VariablesIcon';
import { NAVBAR_HEIGHT_PX } from '../common/constants/constants';
import { GlobalContext } from '../GlobalContext';
import { UserContext } from '../user/user.context';

import { BotContext } from './bot.context';
import BroadcastsTab from './broadcasts.tab';
import ChannelsTab from './channels.tab';
import Drawer from './drawer';
import FlowsTab from './flows.tab';
import PostbackTab from './postback.tab';
import SettingsTab from './settings.tab';
import StatisticsTab from './statistics.tab';
import {
  ITelegramBot,
  TelegramMessengerAccess,
} from './telegram-bot.interface';
import VariablesTab from './variables.tab';

export interface IBotTab {
  label: string;
  icon?: ReactNode;
  disabled?: boolean;
  component?: ReactNode;
  tabName: string;
}

const MESSENGER_INDEX = 3;

const BotPage = () => {
  const { user } = useContext(UserContext);
  const { bot, access, firstTab } = useContext(BotContext);
  const { resetFlowName } = useContext(GlobalContext);

  const navigate = useNavigate();
  const { botTab } = useParams();

  const [drawerOpen, setDrawerOpen] = useState(true);

  const toggleDrawer = () => setDrawerOpen((prev) => !prev);

  const collapseDrawer = () => setDrawerOpen(false);

  const [currentTabIndex, setCurrentTabIndex] = useState(firstTab);

  const TABS: IBotTab[] = [
    {
      icon: <CallSplitIcon />,
      label: t('bot-page.tabs.flows'),
      component: <FlowsTab collapseDrawer={collapseDrawer} />,
      disabled: !access?.flows,
      tabName: 'flows',
    },
    {
      disabled: !access?.broadcasts,
      label: t('common.broadcasts'),
      icon: <EmailRoundedIcon />,
      component: <BroadcastsTab />,
      tabName: 'broadcasts',
    },
    {
      disabled: !access?.channels,
      label: t('bot-page.tabs.channels'),
      icon: <CampaignRoundedIcon />,
      component: <ChannelsTab bot={bot as ITelegramBot} />,
      tabName: 'channels',
    },
    {
      disabled: access?.messenger === TelegramMessengerAccess.none,
      label: t('bot-page.tabs.messages'),
      icon: <ChatRoundedIcon />,
      tabName: 'messenger',
    },
    {
      disabled: !access?.statistics,
      label: t('bot-page.tabs.statistics'),
      icon: <TrendingUpRoundedIcon />,
      component: <StatisticsTab botId={bot?._id as string} />,
      tabName: 'statistics',
    },
    {
      disabled: user?._id !== bot?.owner,
      label: t('bot-page.tabs.settings'),
      icon: <SettingsRoundedIcon />,
      component: <SettingsTab bot={bot} />,
      tabName: 'settings',
    },
    {
      disabled: !access?.statistics,
      label: t('bot-page.tabs.postback'),
      icon: <LinkIcon />,
      component: <PostbackTab />,
      tabName: 'postbacks',
    },
    {
      disabled: !access?.variables,
      label: t('bot-page.tabs.variables'),
      icon: <VariablesIcon fill={currentTabIndex === 7 ? '#CAFA44' : '#fff'} />,
      component: <VariablesTab />,
      tabName: 'variables',
    },
  ];

  const onChangeTab = (_: React.SyntheticEvent, newValue: number) => {
    if (newValue === MESSENGER_INDEX) {
      resetFlowName();

      navigate(`/project/${bot.project}/messenger/${bot._id}`);
      return;
    }

    if (TABS[newValue].disabled) {
      return;
    }

    navigate(
      `/project/${bot.project}/bot/${bot._id}/${TABS[newValue].tabName}`,
    );
  };

  useEffect(() => {
    if (!botTab) {
      setCurrentTabIndex(firstTab);
    }

    const foundIndex = TABS.findIndex((tab) => tab.tabName === botTab);
    if (foundIndex === -1) return;

    if (TABS[foundIndex]?.disabled) return;

    setCurrentTabIndex(foundIndex);
  }, [botTab, TABS]);

  return (
    <Stack direction="row">
      <Stack>
        <Drawer
          open={drawerOpen}
          onToggle={toggleDrawer}
          tabs={TABS}
          currentTabIndex={currentTabIndex}
          onTabChange={onChangeTab}
        />
      </Stack>
      <Stack
        sx={{
          width: '100%',
          maxHeight: `calc(100vh - ${NAVBAR_HEIGHT_PX}px)`,
          overflowY: 'scroll',
        }}
      >
        {TABS[currentTabIndex]?.component}
      </Stack>
    </Stack>
  );
};

export default BotPage;
