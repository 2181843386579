import ChatRoundedIcon from '@mui/icons-material/ChatRounded';
import StarIcon from '@mui/icons-material/Star';
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';
import {
  Checkbox,
  FormControlLabel,
  Paper,
  Radio,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import React from 'react';

import TooltipInfo from '../../common/tooltip/tooltip';
import { IUserSafe } from '../../user/user.interface';
import {
  ITelegramUserAccess,
  TelegramUserMessengerAccess,
} from '../telegram-user.interface';

interface AccessTableProps {
  owner: string;
  members: IUserSafe[];
  accesses: ITelegramUserAccess[];
  setAccesses: (accesses: ITelegramUserAccess[]) => void;
}

function AccessTableUserBot({
  members,
  accesses,
  setAccesses,
  owner,
}: AccessTableProps) {
  const handleCheckboxChange = (
    memberId: string,
    key: keyof ITelegramUserAccess,
    value: boolean | TelegramUserMessengerAccess,
  ) => {
    setAccesses(
      accesses.map((access) =>
        access.user === memberId ? { ...access, [key]: value } : access,
      ),
    );
  };
  return (
    <Paper sx={{ width: '100%', overflow: 'hidden', flex: '1 1 auto' }}>
      <TableContainer
        component={Paper}
        sx={{
          width: '100%',
          maxHeight: 440,
        }}
      >
        <Table
          stickyHeader
          aria-label="sticky table"
          sx={{
            border: '1px solid',
            borderColor: 'grey.12',
            borderRadius: '8px',
            overflow: 'hidden',
          }}
        >
          <TableHead
            sx={{
              backgroundColor: 'grey.10',
            }}
          >
            <TableRow
              sx={{
                backgroundColor: 'grey.10',
                border: '1px solid',
                borderColor: 'grey.10',
                borderRadius: '8px',
                position: 'sticky',
                top: '0',
                zIndex: '12',
              }}
            >
              <TableCell
                sx={{
                  backgroundColor: 'transparent',
                  borderBottom: 'none',
                  opacity: 0.6,
                }}
              >
                {t('common.name')}
              </TableCell>

              <TableCell
                sx={{
                  backgroundColor: 'transparent',
                  borderBottom: 'none',
                  opacity: 0.6,
                }}
              >
                {t('common.lastName')}
              </TableCell>
              <TableCell
                sx={{
                  backgroundColor: 'transparent',
                  borderBottom: 'none',
                  padding: '16px 6px 1px',
                }}
                align="center"
              >
                <Stack flexDirection="column" alignItems="center">
                  <TooltipInfo
                    icon={
                      <ChatRoundedIcon
                        sx={{ color: 'grey.5', fontWeight: 500 }}
                      />
                    }
                    title={t(
                      'projects.screen-new-telegram-bot.participantsTable.tooltips.accessMessenger',
                    )}
                    placement="top"
                  />
                  <Typography sx={{ fontSize: '8px', fontWeight: 500 }}>
                    {t('common.messenger')}
                  </Typography>
                </Stack>
              </TableCell>
              <TableCell
                sx={{
                  backgroundColor: 'transparent',
                  borderBottom: 'none',
                  padding: '16px 6px 1px',
                }}
                align="center"
              >
                <Stack flexDirection="column" alignItems="center">
                  <TooltipInfo
                    icon={
                      <SupportAgentRoundedIcon
                        sx={{ color: 'grey.5', fontWeight: 500 }}
                      />
                    }
                    title={t(
                      'projects.screen-new-telegram-bot.participantsTable.tooltips.accessManager',
                    )}
                    placement="top"
                  />
                  <Typography sx={{ fontSize: '8px', fontWeight: 500 }}>
                    {t('common.manager')}
                  </Typography>
                </Stack>
              </TableCell>
              <TableCell
                sx={{
                  backgroundColor: 'transparent',
                  borderBottom: 'none',
                  padding: '16px 6px 1px',
                }}
                align="center"
              >
                <Stack flexDirection="column" alignItems="center">
                  <TooltipInfo
                    icon={
                      <StarIcon sx={{ color: 'grey.5', fontWeight: 500 }} />
                    }
                    title={t(
                      'projects.screen-new-telegram-bot.participantsTable.tooltips.accessAdmin',
                    )}
                    placement="top"
                  />
                  <Typography sx={{ fontSize: '8px', fontWeight: 500 }}>
                    {t('common.admin')}
                  </Typography>
                </Stack>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {members.map((member, index) => {
              const access =
                accesses.find((access) => access.user === member._id) ||
                ({} as ITelegramUserAccess);
              const isEven = index % 2 === 0;
              return (
                <TableRow
                  sx={{
                    backgroundColor: isEven ? 'grey.15' : 'grey.14',
                    border: '1px solid ',
                    borderColor: 'grey.12',
                  }}
                  key={member._id}
                >
                  <TableCell
                    sx={{ border: 'none', padding: '6px 16px' }}
                    component="th"
                    scope="row"
                  >
                    {member?.firstName ?? ''}
                  </TableCell>
                  <TableCell
                    sx={{ border: 'none', padding: '6px 16px' }}
                    component="th"
                    scope="row"
                  >
                    {member.lastName ?? ''}
                  </TableCell>

                  <TableCell
                    align="center"
                    sx={{
                      border: 'none',
                      padding: '6px 16px',
                    }}
                  >
                    <FormControlLabel
                      sx={{ margin: '0' }}
                      control={
                        <Checkbox
                          style={{
                            color:
                              access.messenger !==
                              TelegramUserMessengerAccess.none
                                ? '#DEFF82'
                                : '#4b4a4a',
                            opacity: member._id === owner ? '0.5' : '1',
                          }}
                          disabled={member._id === owner}
                          checked={
                            access.messenger !==
                              TelegramUserMessengerAccess.none ||
                            member._id === owner
                          }
                          onChange={(event) =>
                            handleCheckboxChange(
                              member._id,
                              'messenger',
                              event.target.checked
                                ? TelegramUserMessengerAccess.manager
                                : TelegramUserMessengerAccess.none,
                            )
                          }
                        />
                      }
                      label=""
                    />
                  </TableCell>
                  <TableCell
                    sx={{ border: 'none', padding: '6px 16px' }}
                    align="center"
                  >
                    <Radio
                      checked={
                        access.messenger === TelegramUserMessengerAccess.manager
                      }
                      onChange={() =>
                        handleCheckboxChange(
                          member._id,
                          'messenger',
                          TelegramUserMessengerAccess.manager,
                        )
                      }
                      style={{
                        color:
                          access.messenger ===
                          TelegramUserMessengerAccess.manager
                            ? '#DEFF82'
                            : '#4C4B4C',
                      }}
                      disabled={
                        access.messenger === TelegramUserMessengerAccess.none ||
                        member._id === owner
                      }
                    />
                  </TableCell>

                  <TableCell
                    sx={{ border: 'none', padding: '6px 16px' }}
                    align="center"
                  >
                    <Radio
                      style={{
                        color:
                          access.messenger === TelegramUserMessengerAccess.admin
                            ? '#DEFF82'
                            : '#4C4B4C',
                        opacity: member._id === owner ? '0.5' : '1',
                      }}
                      checked={
                        access.messenger ===
                          TelegramUserMessengerAccess.admin ||
                        member._id === owner
                      }
                      onChange={() =>
                        handleCheckboxChange(
                          member._id,
                          'messenger',
                          TelegramUserMessengerAccess.admin,
                        )
                      }
                      disabled={
                        access.messenger === TelegramUserMessengerAccess.none ||
                        member._id === owner
                      }
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export default AccessTableUserBot;
