import CloseIcon from '@mui/icons-material/Close';
import { Button, Stack, Typography } from '@mui/material';
import { t } from 'i18next';
import React, { ChangeEvent, FC, useContext, useEffect, useState } from 'react';

import createInviteLink from '../../../api/projects/create-invite-link';
import { AppContext } from '../../../AppContext';
import ModalBox from '../../../common/modal/modal-box';
import { APP_CONFIG } from '../../../config';
import { GlobalContext } from '../../../GlobalContext';
import {
  ITelegramBotWithAccess,
  TelegramMessengerAccess,
} from '../../../telegram-bots/telegram-bot.interface';
import { ITelegramUserAccountWithAccess } from '../../../telegram-user-accounts/telegram-user.interface';
import { ProjectContext } from '../../project.context';
import { ProjectInviteAccess } from '../../project.interface';

import TableInviteLink from './table-invite-link';

type CreateInviteLinkModalProps = {
  isOpen: boolean;
  onClose: () => void;
  projectId: string;
};
const CreateInviteLinkModal: FC<CreateInviteLinkModalProps> = ({
  isOpen,
  onClose,
  projectId,
}) => {
  const { telegramBots, telegramUsers } = useContext(ProjectContext);
  const { triggerSnackbar } = useContext(AppContext);
  const { refetchProjects } = useContext(GlobalContext);

  const [accesses, setAccesses] = useState<ProjectInviteAccess[]>([]);
  const [nameInviteLink, setNameInviteLink] = useState<string>('');
  const [groupOfBots, setGroupOfBots] = useState<
    (ITelegramBotWithAccess | ITelegramUserAccountWithAccess)[]
  >([]);

  const onChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    setNameInviteLink(e.target.value);
  };

  const handleCheckboxChange = (
    currentBotId: string,
    key: keyof ProjectInviteAccess,
    value: boolean | TelegramMessengerAccess,
  ) => {
    const matchingBotOrUser = groupOfBots.find((botOrUser) => {
      if ('businessAccount' in botOrUser) {
        return botOrUser.businessAccount._id === currentBotId;
      } else if ('bot' in botOrUser) {
        return botOrUser.bot._id === currentBotId;
      }
      return false;
    });

    const botType = matchingBotOrUser
      ? 'bot' in matchingBotOrUser
        ? 'BOT'
        : 'BUSINESS_ACCOUNT'
      : '';

    setAccesses(
      accesses.map((acc) => {
        if (botType === 'BOT' && acc.telegramBot === currentBotId) {
          return { ...acc, [key]: value };
        } else if (
          botType === 'BUSINESS_ACCOUNT' &&
          acc.businessAccount === currentBotId
        ) {
          return { ...acc, [key]: value };
        }
        return acc;
      }),
    );
  };

  const checkAllCheckboxChange = (telegramBotId: string, value: boolean) => {
    setAccesses(
      accesses.map((acc) =>
        acc.telegramBot === telegramBotId
          ? {
              ...acc,
              broadcasts: value,
              channels: value,
              flows: value,
              statistics: value,
              variables: value,
              messenger: value
                ? TelegramMessengerAccess.manager
                : TelegramMessengerAccess.none,
            }
          : acc,
      ),
    );
  };

  const onCreateInviteLink = () => {
    createInviteLink(projectId, {
      name: nameInviteLink,
      accesses,
    }).then((result) => {
      navigator.clipboard.writeText(
        `https://t.me/${APP_CONFIG.TELEGRAM_AUTH_BOT_USERNAME}?start=jp-${result.code}` as string,
      );
      refetchProjects();
      triggerSnackbar(
        t('projects.inviteLinks.createdAndCopiedLink'),
        'success',
      );
      setAccesses([]);
      setNameInviteLink('');
      onClose();
    });
  };

  const createBotsGroup = () => {
    const updatedGroupOfBots: (
      | ITelegramBotWithAccess
      | ITelegramUserAccountWithAccess
    )[] = [];

    for (const bot of telegramBots) {
      updatedGroupOfBots.push(bot);
      const users = telegramUsers.filter(
        (user) => user.businessAccount.telegramBot === bot.bot._id,
      );
      updatedGroupOfBots.push(...users);
    }

    return updatedGroupOfBots;
  };

  useEffect(() => {
    setGroupOfBots([...createBotsGroup()]);
  }, [telegramBots, telegramUsers]);

  useEffect(() => {
    if (isOpen) {
      const newAccesses = groupOfBots.map(
        (
          botOrUser: ITelegramBotWithAccess | ITelegramUserAccountWithAccess,
        ) => {
          let botType = '';
          let currentBotId: string | null = null;

          if ('businessAccount' in botOrUser) {
            botType = 'BUSINESS_ACCOUNT';
            currentBotId = botOrUser.businessAccount._id;
          } else if ('bot' in botOrUser) {
            botType = 'BOT';
            currentBotId = botOrUser.bot._id;
          }

          return {
            telegramBot: botType === 'BOT' ? currentBotId : null,
            businessAccount:
              botType === 'BUSINESS_ACCOUNT' ? currentBotId : null,
            botType: botType,
            broadcasts: false,
            channels: false,
            flows: false,
            statistics: false,
            variables: false,
            messenger: TelegramMessengerAccess.none,
          };
        },
      );

      setAccesses(newAccesses);
    }
  }, [isOpen]);

  return (
    <ModalBox
      open={isOpen}
      onClose={() => {
        onClose();
        setNameInviteLink('');
      }}
      maxWidth="710px"
      sx={{
        p: '24px 32px',
        borderRadius: '12px',
        backgroundColor: 'grey.14',
        position: 'relative',
      }}
    >
      <Stack
        sx={{
          position: 'absolute',
          top: '15px',
          right: '15px',
          cursor: 'pointer',
        }}
        onClick={() => {
          onClose();
          setNameInviteLink('');
        }}
      >
        <CloseIcon />
      </Stack>
      <Stack>
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: '24px',
            textAlign: 'center',
          }}
        >
          {t('projects.inviteLinks.createLink')}
        </Typography>

        <TableInviteLink
          accesses={accesses}
          groupOfBots={groupOfBots}
          nameInviteLink={nameInviteLink}
          onChangeName={onChangeName}
          handleCheckboxChange={handleCheckboxChange}
          checkAllCheckboxChange={checkAllCheckboxChange}
        />
        <Button
          className="brightGreen"
          onClick={onCreateInviteLink}
          disabled={!Boolean(nameInviteLink)}
        >
          {t('projects.inviteLinks.createAndCopyLink')}
        </Button>
      </Stack>
    </ModalBox>
  );
};

export default CreateInviteLinkModal;
