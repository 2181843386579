import CloseIcon from '@mui/icons-material/Close';
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import React, { FC, SyntheticEvent, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import ModalBox from '../../../../common/modal/modal-box';
import { BotContext } from '../../../bot.context';
import { ITelegramChannelWithActivators } from '../../../channels/interfaces';
import {
  ActionFlowNodeTaskData,
  ActionTaskTypes,
  IFlowWithActivatorsAndFolder,
} from '../../interfaces';

import ActionsWrapperModal from './actions-wrapper.modal';

type ActionsModalProps = {
  creatingTask: ActionFlowNodeTaskData | null;
  open: boolean;
  onClose: () => void;
  addToTasks: () => void;
  onChangeCreatingTask: (data: ActionFlowNodeTaskData) => void;
  onSelectChannel: (
    event: SelectChangeEvent,
    actionType: ActionTaskTypes,
  ) => void;
  channels: ITelegramChannelWithActivators[];
  onSelectFlow: (event: SelectChangeEvent) => void;
  flows: IFlowWithActivatorsAndFolder[];
};

const ActionsModal: FC<ActionsModalProps> = ({
  creatingTask,
  open,
  onClose,
  addToTasks,
  onChangeCreatingTask,
  onSelectChannel,
  channels,
  onSelectFlow,
  flows,
}) => {
  const { bot } = useContext(BotContext);

  const navigate = useNavigate();
  return (
    <ModalBox
      open={open}
      onClose={onClose}
      maxWidth="400px"
      sx={{
        height: '220px',
        p: '24px 16px',
        overflow: 'hidden',
        border: '1px solid',
        borderColor: 'grey.10',
        borderRadius: '12px',
        width: '400px',
        position: 'relative',
      }}
    >
      <Button
        onClick={onClose}
        sx={{
          width: '24px',
          minWidth: '24px',
          height: '24px',
          position: 'absolute',
          top: '5px',
          right: '5px',
          backgroundColor: 'transparent',
        }}
      >
        <CloseIcon sx={{ fontSize: '18px', color: 'grey.1', opacity: '0.6' }} />
      </Button>
      {creatingTask &&
        (() => {
          switch (creatingTask.type) {
            case ActionTaskTypes.assignParam: {
              return (
                <ActionsWrapperModal
                  title={t('flowNodes.assignParam')}
                  addToTasks={addToTasks}
                  onClose={onClose}
                  disabledButton={Boolean(
                    !creatingTask.value || !creatingTask.key,
                  )}
                >
                  <Autocomplete
                    sx={{
                      width: '100%',
                      '& .MuiInputBase-root': {
                        backgroundColor: 'grey.15',
                        height: '56px',
                        p: '0',
                        pl: '12px',
                      },
                    }}
                    options={bot.params}
                    value={creatingTask.key}
                    onInputChange={(event, newInputValue) => {
                      onChangeCreatingTask({
                        ...creatingTask,
                        inputValue: newInputValue ?? ' ',
                      });
                    }}
                    onChange={(e: SyntheticEvent, newValue: string | null) => {
                      onChangeCreatingTask({
                        ...creatingTask,
                        inputValue: newValue ?? '',
                        key: newValue ?? '',
                      });
                    }}
                    inputValue={creatingTask.inputValue || ''}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={t('common.key')}
                        sx={{
                          '& .MuiInputBase-input': {
                            fontWeight: 500,
                            fontSize: '18px',
                          },
                        }}
                      />
                    )}
                    noOptionsText={
                      <Stack sx={{ textAlign: 'center' }}>
                        <Typography
                          sx={{ mt: '15px', fontWeight: 500, fontSize: '14px' }}
                        >
                          {t('common.notFoundVariable')}
                        </Typography>
                        <Button
                          onClick={() =>
                            navigate(
                              `/project/${bot?.project}/bot/${bot?._id}/variables`,
                            )
                          }
                          sx={{ mt: '15px', fontSize: '15px' }}
                        >
                          {t('common.createVariable')}
                        </Button>
                      </Stack>
                    }
                  />
                  <TextField
                    sx={{
                      width: '100%',
                      '& .MuiInputBase-root': {
                        height: '56px',
                        fontWeight: 500,
                        fontSize: '18px',
                      },
                    }}
                    placeholder={t('common.value')}
                    value={creatingTask.value}
                    onChange={(e) => {
                      const value = e.target.value.trim();
                      onChangeCreatingTask({
                        ...creatingTask,
                        value,
                      });
                    }}
                  />
                </ActionsWrapperModal>
              );
            }

            case ActionTaskTypes.deleteParam:
              return (
                <ActionsWrapperModal
                  title={t('flowNodes.deleteParam')}
                  addToTasks={addToTasks}
                  onClose={onClose}
                  disabledButton={Boolean(!creatingTask.key)}
                >
                  <Autocomplete
                    sx={{
                      width: '100%',
                      '& .MuiInputBase-root': {
                        backgroundColor: 'grey.15',
                        height: '56px',
                        p: '0',
                        pl: '12px',
                      },
                    }}
                    options={bot.params}
                    value={creatingTask.key}
                    onInputChange={(event, newInputValue) => {
                      onChangeCreatingTask({
                        ...creatingTask,
                        inputValue: newInputValue ?? '',
                      });
                    }}
                    onChange={(e: SyntheticEvent, newValue: string | null) => {
                      onChangeCreatingTask({
                        ...creatingTask,
                        inputValue: newValue ?? '',
                        key: newValue ?? '',
                      });
                    }}
                    inputValue={creatingTask.inputValue || ''}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={t('common.key')}
                        sx={{
                          p: '0',
                          '& .MuiInputBase-input': {
                            p: '0',
                          },
                        }}
                      />
                    )}
                    noOptionsText={
                      <Stack sx={{ textAlign: 'center' }}>
                        <Typography
                          sx={{ mt: '15px', fontWeight: 500, fontSize: '14px' }}
                        >
                          {t('common.notFoundVariable')}
                        </Typography>
                        <Button
                          onClick={() =>
                            navigate(
                              `/project/${bot?.project}/bot/${bot?._id}/variables`,
                            )
                          }
                          sx={{ mt: '15px' }}
                        >
                          {t('common.createVariable')}
                        </Button>
                      </Stack>
                    }
                  />
                </ActionsWrapperModal>
              );

            case ActionTaskTypes.acceptToChannel:
              return (
                <ActionsWrapperModal
                  title={t('flowNodes.acceptToChannel')}
                  addToTasks={addToTasks}
                  onClose={onClose}
                  disabledButton={Boolean(!creatingTask?.channelId)}
                >
                  <Box sx={{ width: '100%' }}>
                    <FormControl fullWidth>
                      <InputLabel>{t('common.channel')}</InputLabel>
                      <Select
                        value={creatingTask?.channelId ?? ''}
                        label="Channel"
                        onChange={(event) => {
                          onSelectChannel(
                            event,
                            ActionTaskTypes.acceptToChannel,
                          );
                        }}
                        sx={{
                          width: '100%',
                          height: '56px',
                          '.MuiSelect-select': {
                            backgroundColor: 'grey.15',
                            py: '14px',
                          },
                        }}
                      >
                        {channels.map(({ title, _id }) => (
                          <MenuItem key={_id} value={_id}>
                            {title}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </ActionsWrapperModal>
              );

            case ActionTaskTypes.kickFromChannel:
              return (
                <ActionsWrapperModal
                  title={t('flowNodes.kickFromChannel')}
                  addToTasks={addToTasks}
                  onClose={onClose}
                  disabledButton={Boolean(!creatingTask?.channelId)}
                >
                  <Box sx={{ width: '100%' }}>
                    <FormControl fullWidth>
                      <InputLabel>{t('common.channel')}</InputLabel>
                      <Select
                        value={creatingTask?.channelId ?? ''}
                        label="Channel"
                        onChange={(event) => {
                          onSelectChannel(
                            event,
                            ActionTaskTypes.kickFromChannel,
                          );
                        }}
                        sx={{
                          width: '100%',
                          height: '56px',
                          '.MuiSelect-select': {
                            backgroundColor: 'grey.15',
                            py: '14px',
                          },
                        }}
                      >
                        {channels.map(({ title, _id }) => (
                          <MenuItem key={_id} value={_id}>
                            {title}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </ActionsWrapperModal>
              );

            case ActionTaskTypes.startFlow:
              return (
                <ActionsWrapperModal
                  title={t('flowNodes.startFlow')}
                  addToTasks={addToTasks}
                  onClose={onClose}
                  disabledButton={Boolean(!creatingTask?.flowId)}
                >
                  <Box sx={{ width: '100%' }}>
                    <FormControl fullWidth>
                      <InputLabel>{t('common.flow')}</InputLabel>
                      <Select
                        value={creatingTask?.flowId ?? ''}
                        label="Flow"
                        onChange={(event) => onSelectFlow(event)}
                        sx={{
                          height: '56px',
                          '.MuiSelect-select': {
                            backgroundColor: 'grey.15',
                            py: '14px',
                          },
                        }}
                      >
                        {flows.map(({ _id, name }) => (
                          <MenuItem key={_id} value={_id}>
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </ActionsWrapperModal>
              );
            case ActionTaskTypes.unbanInChannel:
              return (
                <ActionsWrapperModal
                  title={t('flowNodes.unbanInChannel')}
                  addToTasks={addToTasks}
                  onClose={onClose}
                  disabledButton={Boolean(!creatingTask?.channelId)}
                >
                  <Box sx={{ width: '100%' }}>
                    <FormControl fullWidth>
                      <InputLabel>{t('common.channel')}</InputLabel>
                      <Select
                        value={creatingTask?.channelId ?? ''}
                        label="Channel"
                        onChange={(event) => {
                          onSelectChannel(
                            event,
                            ActionTaskTypes.unbanInChannel,
                          );
                        }}
                        sx={{
                          height: '56px',
                          '.MuiSelect-select': {
                            backgroundColor: 'grey.15',
                            py: '14px',
                          },
                        }}
                      >
                        {channels.map(({ title, _id }) => (
                          <MenuItem key={_id} value={_id}>
                            {title}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </ActionsWrapperModal>
              );
          }
        })()}
    </ModalBox>
  );
};

export default ActionsModal;
