import { CircularProgress, Divider, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { t } from 'i18next';
import React, {
  FC,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
} from 'react';
import { useInView } from 'react-intersection-observer';

import { ProjectContext } from '../../projects/project.context';
import { BotContext } from '../../telegram-bots/bot.context';
import { UserAccountContext } from '../../telegram-user-accounts/user-account.context';
import { useScrollRestoration } from '../../utils/hooks/useScrollRestoration';
import FilterContainer from '../filter/filter.container';
import { MessengerContext, TelegramDialog } from '../messenger.context';

import DialogItem from './dialog.item';

dayjs.extend(relativeTime).locale('uk');

interface DialogsListProps {
  currentDialog: TelegramDialog | null;
  onSelectDialog: (dialog: TelegramDialog) => void;
}

const DialogsList: FC<DialogsListProps> = ({
  currentDialog,
  onSelectDialog,
}) => {
  const { dialogs, loadingDialogs, getDialogs, hasMoreDialogs, dialogsFilter } =
    useContext(MessengerContext);
  const { project } = useContext(ProjectContext);
  const { bot } = useContext(BotContext);
  const { userAccount } = useContext(UserAccountContext);

  const activeBot = bot ? bot : userAccount;

  const { ref: inViewRef, inView } = useInView({
    threshold: 0,
  });

  const { handleScroll, restoreScroll } = useScrollRestoration();

  const ref = useRef<HTMLDivElement | null>(null);

  useLayoutEffect(() => {
    restoreScroll(ref.current);
  }, [dialogs]);

  useEffect(() => {
    if (inView && hasMoreDialogs) {
      getDialogs();
    }
  }, [inView, hasMoreDialogs, dialogsFilter]);

  return (
    <Stack
      ref={ref}
      onScroll={() => handleScroll(ref.current)}
      direction="column"
      sx={{
        height: '100%',
        overflowY: 'scroll',
        position: 'relative',
      }}
    >
      <Stack
        sx={{
          top: '0',
          width: '100%',
          position: 'sticky',
          p: '12px',
          zIndex: 2,
        }}
      >
        <FilterContainer />
      </Stack>
      <Stack
        sx={{
          height: '90%',
          overflow: 'auto',
        }}
      >
        <Stack
          gap="8px"
          sx={{
            px: '10px',
          }}
        >
          {!dialogs.length && !loadingDialogs && (
            <Typography
              sx={{
                m: '16px auto ',
                fontWeight: 500,
                fontSize: '16px',
                color: 'grey.secondary',
              }}
            >
              {t('common.nothingFound')}
            </Typography>
          )}
          {dialogs.map((dialog) => (
            <DialogItem
              key={dialog._id}
              dialog={dialog}
              lastMessageDate={dayjs(dialog.updatedAt).fromNow(true)}
              onSelect={() => {
                onSelectDialog(dialog);
              }}
              isActive={currentDialog?._id === dialog._id}
            />
          ))}
          {loadingDialogs && (
            <Stack
              sx={{
                height: '50px',
                width: 'max-content',
                m: '10px auto 0',
                color: 'green.2',
              }}
            >
              <CircularProgress color="inherit" />
            </Stack>
          )}
          <Stack
            sx={{
              minHeight: '5px',
              mb: loadingDialogs ? '0' : '100px',
              zIndex: 2,
            }}
            ref={inViewRef}
          />
        </Stack>
      </Stack>
      <Divider />
      <Stack
        direction="row"
        sx={{
          minHeight:
            project.name.length + activeBot.name.length > 45 ? '149px' : '76px',
          mt: 'auto',
          flexDirection: 'column',
          p: '12px',
        }}
      >
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: '16px',
          }}
        >
          {t('common.project')}:{' '}
          <Typography
            sx={{ color: 'green.3', fontWeight: 500 }}
            component="span"
          >
            {project.name}
          </Typography>
        </Typography>
        <Typography
          sx={{
            marginTop: 'auto',
            fontWeight: 500,
            fontSize: '16px',
          }}
        >
          {t('common.bot')}:{' '}
          <Typography
            sx={{ color: 'green.3', fontWeight: 500 }}
            component="span"
          >
            {activeBot.name}
          </Typography>
        </Typography>
      </Stack>
    </Stack>
  );
};

export default DialogsList;
