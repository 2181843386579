import axios from '../axios';

const getProjectTelegramUserAccesses = async (userAccount: string) => {
  const { data } = await axios.get(
    `/business-accounts/${userAccount}/accesses`,
  );

  return data;
};

export default getProjectTelegramUserAccesses;
