import AddIcon from '@mui/icons-material/Add';
import SettingsIcon from '@mui/icons-material/Settings';
import { Divider, IconButton, Stack } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import ArrowLeft from '../assets/icons/ArrowLeft';
import { GlobalContext } from '../GlobalContext';
import { ProjectContext } from '../projects/project.context';
import TelegramAccountThumb from '../telegram-user-accounts/telegram-bot.thumb';
import { ITelegramUserAccountWithAccess } from '../telegram-user-accounts/telegram-user.interface';
import { UserContext } from '../user/user.context';

import { ITelegramBotWithAccess } from './telegram-bot.interface';

const BotsList = () => {
  const { resetFlowName } = useContext(GlobalContext);
  const { user } = useContext(UserContext);
  const { telegramBots, telegramUsers, project } = useContext(ProjectContext);

  const { projectId, botId } = useParams();
  const navigate = useNavigate();

  const [groupOfBots, setGroupOfBots] = useState<
    (ITelegramBotWithAccess | ITelegramUserAccountWithAccess)[][]
  >([]);

  const navigateToSettingPage = () => {
    if (window.location.pathname.includes('settings') && !botId) {
      window.history.back();
    } else {
      navigate(`/project/${projectId}/settings`);
      resetFlowName();
    }
  };

  const createBotsGroup = () => {
    const updatedGroupOfBots: (
      | ITelegramBotWithAccess
      | ITelegramUserAccountWithAccess
    )[][] = [];

    for (const bot of telegramBots) {
      const users = telegramUsers.filter(
        (user) => user.businessAccount.telegramBot === bot.bot._id,
      );

      updatedGroupOfBots.push([{ ...bot }, ...users]);
    }

    return updatedGroupOfBots;
  };

  useEffect(() => {
    setGroupOfBots([...createBotsGroup()]);
  }, [telegramBots, telegramUsers]);

  useEffect(() => {
    if (createBotsGroup().length < 1 && telegramUsers.length > 0) {
      setGroupOfBots([telegramUsers]);
    } else if (createBotsGroup().length !== groupOfBots.length) {
      setGroupOfBots([...createBotsGroup()]);
    }
  }, [groupOfBots]);

  const openCreateBotPage = () => {
    navigate(`/project/${projectId}/new-telegram-bot`);
  };

  const isProjectOwner = project.owner === user?._id;

  return (
    <Stack
      sx={{
        padding: '8px',
        width: '81px',
        backgroundColor: 'grey.14',
        borderRight: '1px solid',
        borderRightColor: 'grey.13',
      }}
    >
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          borderRadius: '4px',
          width: '100%',
          height: '56px',
          minHeight: '56px',
          backgroundColor: '#454a33',
          cursor: 'pointer',
        }}
        onClick={navigateToSettingPage}
      >
        {window.location.pathname.includes('settings') && !botId ? (
          <ArrowLeft />
        ) : (
          <SettingsIcon
            sx={{
              width: '26px',
              height: '24px',
              minHeight: '24px',
              color: 'grey.1',
              opacity: '0.8',
            }}
          />
        )}
      </Stack>
      <Divider
        sx={{
          m: '12px 0',
        }}
      />
      {isProjectOwner && (
        <Stack
          sx={{
            mb: '12px',
            backgroundColor: 'grey.10',
            borderRadius: '50%',
            mx: 'auto',
          }}
          alignItems="center"
          justifyContent="center"
        >
          <IconButton size="large" onClick={openCreateBotPage}>
            <AddIcon fontSize="inherit" />
          </IconButton>
        </Stack>
      )}
      <Stack
        gap="12px"
        sx={{
          overflow: 'auto',
        }}
      >
        {groupOfBots.map((groupOfBot, index) => {
          return (
            <Stack
              key={index}
              sx={{
                backgroundColor:
                  groupOfBot.length > 1 ? 'grey.11' : 'transparent',
                borderRadius: '12px',
                overflow: 'hidden',
                minHeight: '80px',
              }}
            >
              {groupOfBot.map(
                (
                  bot: ITelegramBotWithAccess | ITelegramUserAccountWithAccess,
                ) => {
                  let botType = '';
                  let currentBotId;
                  if ('businessAccount' in bot) {
                    botType = 'BUSINESS_ACCOUNT';
                    currentBotId = bot.businessAccount._id;
                  } else if ('bot' in bot) {
                    botType = 'BOT';
                    currentBotId = bot.bot._id;
                  }
                  return (
                    <Stack key={currentBotId}>
                      <TelegramAccountThumb
                        bot={bot}
                        selected={currentBotId === botId}
                        botType={botType}
                        key={currentBotId}
                      />
                    </Stack>
                  );
                },
              )}
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  );
};

export default BotsList;
