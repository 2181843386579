import AddRoundedIcon from '@mui/icons-material/AddRounded';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Autocomplete,
  Button,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import React, {
  ChangeEvent,
  FC,
  SyntheticEvent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

import { AppContext } from '../../AppContext';
import CopyableText from '../../common/copyableText/copyableText';
import Input from '../../common/inputs/InputWithIcon';
import { Params } from '../../interfaces/common';
import { BotContext } from '../bot.context';

import {
  CreateFlowActivator,
  IFlowActivator,
  UpdateFlowActivator,
} from './interfaces';

const MAX_NAME_LENGTH = 35;

interface CreateNewActivatorProps {
  onCreateNewActivator: (newFlowActivator: CreateFlowActivator) => void;
  creatingNewActivator: boolean;
  isCopyActivator: boolean;
  onEditActivator: (activatorToUpdate: UpdateFlowActivator) => void;
  selectedActivator?: IFlowActivator | null;
  onResetSelectedActivator: () => void;
}

const CreateEditActivator: FC<CreateNewActivatorProps> = ({
  onCreateNewActivator,
  creatingNewActivator,
  isCopyActivator,
  onEditActivator,
  selectedActivator,
  onResetSelectedActivator,
}) => {
  const { triggerSnackbar } = useContext(AppContext);
  const { bot } = useContext(BotContext);

  const navigate = useNavigate();

  const [name, setName] = useState('');

  const [params, setParams] = useState<Params>({});
  const [inputValueKey, setInputValueKey] = useState<Record<string, string>>(
    {},
  );

  if (!bot) return null;

  const isEqual = (a: Params, b: Params): boolean => {
    const aKeys = Object.keys(a);
    const bKeys = Object.keys(b);

    if (aKeys.length !== bKeys.length) return false;

    for (const key of aKeys) {
      if (a[key] !== b[key]) return false;
    }

    return true;
  };

  const valid = useMemo(
    () =>
      Boolean(name) &&
      (selectedActivator?.name !== name ||
        !isEqual(selectedActivator?.params || {}, params)) &&
      Object.entries(params).every(
        ([key, value]) => Boolean(key) && Boolean(value),
      ),
    [name, params, selectedActivator],
  );

  const onAddParam = () => {
    setParams((prev) => ({ ...prev, '': '' }));
  };

  const onParamChange = (
    key: string,
    fieldName: 'key' | 'value',
    newValue: string | null,
  ) => {
    setParams((prev) => {
      const updatedParams = { ...prev };
      if (newValue === null) {
        const value = updatedParams[key];
        delete updatedParams[key];
        updatedParams[''] = value;
      } else {
        if (fieldName === 'key') {
          const value = updatedParams[key];
          delete updatedParams[key];
          updatedParams[newValue] = value;

          setInputValueKey((prev) => {
            const updatedInputValues = { ...prev };
            delete updatedInputValues[key];
            updatedInputValues[newValue] = '';
            return updatedInputValues;
          });
        } else {
          updatedParams[key] = newValue;
        }
      }
      return updatedParams;
    });
  };

  const onParamDelete = (key: string) => {
    setParams((prev) => {
      const updatedParams = { ...prev };
      delete updatedParams[key];
      return updatedParams;
    });

    setInputValueKey((prev) => {
      const updatedInputValues = { ...prev };
      delete updatedInputValues[key];
      return updatedInputValues;
    });
  };

  const getFilteredOptions = (selectedKey: string) => {
    const selectedKeys = Object.keys(params);
    return bot.params.filter(
      (param) => param === selectedKey || !selectedKeys.includes(param),
    );
  };

  const onNameInput = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value.length <= MAX_NAME_LENGTH) {
      setName(value);
    }
  };

  const onSave = () => {
    if (creatingNewActivator || isCopyActivator) {
      onCreateNewActivator({ params, name });
      onResetSelectedActivator();
    } else {
      onEditActivator({ name, params });
    }
  };

  const copyScript = (text: string) => {
    navigator.clipboard.writeText(text);
    triggerSnackbar(t('copyableText.text'));
  };

  useEffect(() => {
    if (selectedActivator) {
      setName(selectedActivator?.name as string);
      setParams(selectedActivator?.params as Params);
    } else {
      setName('');
      setParams({});
    }
  }, [selectedActivator]);

  return (
    <Stack
      sx={{
        width: '100%',
        height: '100%',
      }}
    >
      <Stack direction="row" alignItems="center" gap="15px">
        <Input
          value={name}
          handleChangeSearch={onNameInput}
          backgroundNarrative={'dark'}
          placeholder={t('common.nameLabel')}
          withIcon={false}
          fullWidth={true}
        />
      </Stack>
      {selectedActivator && !isCopyActivator && (
        <Stack
          direction="row"
          alignItems="center"
          gap="15px"
          sx={{
            mt: '10px',
            border: '1px solid',
            borderColor: 'grey.13',
            borderRadius: '6px',
            padding: ' 12px 10px 12px 16px',
          }}
        >
          <CopyableText
            text={`https://t.me/${bot.username}?start=f-${selectedActivator._id}`}
          />
          <Button
            className="green"
            sx={{
              width: '56px',
              minWidth: '56px',
              height: '56px',
            }}
            onClick={() =>
              copyScript(
                `https://t.me/${bot.username}?start=f-${selectedActivator._id}`,
              )
            }
          >
            <ContentCopyIcon />
          </Button>
        </Stack>
      )}
      {Boolean(params.length) && (
        <Typography
          sx={{
            mt: '24px',
            fontSize: '18px',
            fontWeight: 500,
          }}
        >
          {t('common.parameters')}
        </Typography>
      )}
      <Stack
        gap="10px"
        sx={{
          mt: '12px',
          maxHeight: '300px',
          overflow: 'auto',
        }}
      >
        {Object.entries(params).map(([key, value], index) => (
          <Stack
            key={'param' + index}
            direction="row"
            alignItems="center"
            gap="15px"
          >
            <Autocomplete
              sx={{
                width: '100%',
                maxWidth: '280px',
                borderRadius: '8px',
                '& .MuiInputBase-root': {
                  borderRadius: '8px',
                  backgroundColor: 'grey.15',
                  height: '56px',
                  p: '0',
                  pl: '12px',
                },
              }}
              value={key}
              onInputChange={(event, newInputValue) => {
                setInputValueKey((prev) => ({
                  ...prev,
                  [key]: newInputValue,
                }));
              }}
              onChange={(e: SyntheticEvent, newValue: string | null) => {
                onParamChange(key, 'key', newValue);
              }}
              inputValue={inputValueKey[key] || ''}
              options={getFilteredOptions(key)}
              renderInput={(params) => (
                <TextField {...params} placeholder={t('common.key')} />
              )}
              noOptionsText={
                <Stack sx={{ textAlign: 'center' }}>
                  <Typography
                    sx={{ mt: '15px', fontWeight: 500, fontSize: '14px' }}
                  >
                    {t('common.notFoundVariable')}
                  </Typography>
                  <Button
                    onClick={() =>
                      navigate(
                        `/project/${bot?.project}/bot/${bot?._id}/variables`,
                      )
                    }
                    sx={{ mt: '15px', fontWeight: 500, fontSize: '14px' }}
                  >
                    {t('common.createVariable')}
                  </Button>
                </Stack>
              }
            />
            <Stack
              sx={{
                width: '100%',
              }}
            >
              <Input
                value={value}
                handleChangeSearch={(e) => {
                  onParamChange(key, 'value', e.target.value.trim());
                }}
                backgroundNarrative={'dark'}
                placeholder={t('common.value')}
                withIcon={false}
              />
            </Stack>
            <Button
              className="red"
              sx={{
                minWidth: '56px',
                height: '56px',
              }}
              onClick={() => {
                onParamDelete(key);
              }}
            >
              <DeleteIcon sx={{ color: 'grey.1', fontSize: '28px' }} />
            </Button>
          </Stack>
        ))}
      </Stack>

      <Button
        sx={{
          color: 'green.2',
          backgroundColor: 'grey.13',
          m: '10px 0',
          fontWeight: '600',
          fontSize: '14px',
          minHeight: '36px',
          '&:hover': { backgroundColor: 'grey.13' },
        }}
        onClick={onAddParam}
      >
        {t('channels.channelActivators.addParam')}
        <AddRoundedIcon
          sx={{
            ml: '5px',
          }}
        />
      </Button>
      <Button
        className="blue"
        sx={{
          mt: 'auto',
          width: '100%',
          minHeight: '56px',
        }}
        onClick={onSave}
        disabled={!valid}
      >
        {t('channels.channelActivators.save')}
      </Button>
    </Stack>
  );
};

export default CreateEditActivator;
